import React from "react";
import { Link } from "react-router-dom";
import Data from "../../Data/data.json";
import Image from "../Image/Image";
import "./Logo.scss";

const Logo = () => {
  return (
    <>
      <Link to="/" className="logo">
        <Image
          src={window.location.origin + `/Images/${Data.Logo.logoImg}`}
          alt=""
        />
      </Link>
    </>
  );
};

export default Logo;
