import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import createStore from "./redux/index";
import ThemeContextWrapper from "./Components/ThemeContext/themeContextWrapper";
import ScrollToTop from "./Components/ScrollToTop";
import ErrorBoundary from "./Components/ErrorBoundry";

const store = createStore();
ReactDOM.render(
  <ThemeContextWrapper>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          {/* <ErrorBoundary> */}
            <ScrollToTop>
              <App />
            </ScrollToTop>
          {/* </ErrorBoundary> */}
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </ThemeContextWrapper>,
  document.getElementById("root")
);
