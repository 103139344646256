import React, { Component, useEffect, useState } from "react";
import Data from "../../Data/data.json";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./Poster.scss";
import Image from "../Image/Image";
import apis from "../apis";

const PosterMobile = () => {
  const [item, setItem] = useState();
  useEffect(() => {
    apis
      .getHomeBannerSlider()
      .then((res) => {
        console.log(res);
        setItem(res[0]?.acf?.home_banner_slider_mobile);
      })
      .catch((err) => console.log(err));
  }, []);

  const postersettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="poster-section mobilebannerslider">
      {/* {item?.map(i=><p>{i?.home_banner_slider_image.url}  {i?.home_banner_slider_image.link}</p> )} */}
      <div className="container">
        <Slider {...postersettings} className="poster-slider-wrap center">
          {item?.map((i, index) => (
            <div key={index} className="poster-slideBox">
              {i?.home_banner_slider_mobile?.url && (
                <a
                  href={
                    i?.home_banner_slider_mobile_link
                      ? i?.home_banner_slider_mobile_link
                      : "/"
                  }
                  target="_blank"
                >
                  <div className="poster-slide-img">
                    <Image
                      src={i?.home_banner_slider_mobile?.url}
                      alt="Slider Image"
                    />
                  </div>
                </a>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default PosterMobile;
