import React, { useEffect, useState } from "react";
import "../TrendingTwitt/TrendingTwitt.scss";
import Image from "../Image/Image";
import Data from "../../Data/data.json";
import {
  TwitterTweetEmbed,
  TwitterTimelineEmbed,
  TwitterVideoEmbed,
} from "react-twitter-embed";
import apis from "../apis";
import loader from "../../Images/loader.gif";

const TrendingTwitt = () => {
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    apis
      .getTrendingTweets()
      .then((res) => {
        setItem(res[0]?.acf?.trending);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className="trending-twitt cmn-padding">
      <div className="container">
        <div className="twitter-home-cover">
          <div className="sec-title">
            <h2 className="black-color">TRENDING TWEETS</h2>
          </div>
          <div className="twitter-home-content">
            {isLoading && (
              <section className="loaderimage">
                <img src={loader} alt="Loading..." />
              </section>
            )}
            {!isLoading &&
              item?.map((i, index) => {
                if (index > 2) {
                  return;
                }
                return (
                  <TwitterTweetEmbed
                    // Here goes your copied ID.
                    tweetId={i?.trending_item}
                    // Style options goes here:
                    optins={{ width: "1280px" }}
                  />
                );
              })}
          </div>
          <div className="btn-link">
            {/* <Link to="/trending">See All</Link>     */}
            <a href="/trending"> See All</a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TrendingTwitt;
