import React, { useState } from "react";
import "./Header.scss";
import Nav from "../Nav/Nav";
import Logo from "../Logo/Logo";
import img from "../../Images/images";

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <header className="headerSection">
        <div className="container">
          <div className="headerWrap">
            <div className="headerLeft">
              <Logo />
            </div>
            <div className="headerRight">
              <div className="sidemenuData">
                <button
                  onClick={handleOpen}
                  className={`sideMenu ${open ? "closeIcon" : ""}`}
                >
                  <img src={open ? img.CLOSE_IMG : img.MENU_IMG} alt="" />
                </button>
              </div>
              <div className={`header-nav ${open ? "open" : ""}`}>
                <Nav />
              </div>
            </div>
            
          </div>
        </div>
      </header>
          
    </>
  );
};

export default Header;
