import { createContext } from "react";

export const themes = {
  dark: "dark-theme",
  light: "light-theme",
};
export const widths = {
  full: "full-width",
  normal: "normal-width",
};

export const ThemeContext = createContext({
  theme: themes.dark,
  changeTheme: () => {},
  width: widths.normal,
  changeWidth: () => {},
});
