// Reducer keys list to manage reducer data
export const MATCH_DETAIL_ID = "MATCH_DETAIL_ID";
export const NEWS_DETAIL = "NEWS_DETAIL";
export const CATEGORIES_POST = "CATEGORIES_POST";
export const FEATURED_MATCHES = "FEATURED_MATCHES";
export const FIREBASE_MATCHES = "FIREBASE_MATCHES";
export const SOCKET_MATCHES = "SOCKET_MATCHES";
export const RELATED_POST = "RELATED_POST";
export const PROJET_ID = "RS_P_1466284058513379332";
export const URL = `https://api.sports.roanuz.com`;
export const API_VERSION = "v5";
export const LOADER = "LOADER";
export const NEWS_LOADING = "NEWS_LOADING";
export const MATCHES_LOADING = "MATCHES_LOADING";
export const ASSOCIATIONS = "ASSOCIATIONS";
export const TOURNAMENTS = "TOURNAMENTS";
export const TOURNAMENTS_LOADING = "TOURNAMENTS_LOADING";
export const ASSOCIATION_LOADING = "ASSOCIATION_LOADING";
export const DOMESTIC_LOADING = "DOMESTIC_LOADING";
export const INTERANTIONAL_LOADING = "INTERNATIONAL_LOADING";
export const TOURNAMENTS_FIXTURES = "TOURNAMENTS_FIXTURES";
export const TOURNAMENTS_FIXTURES_LOADING = "TOURNAMENTS_FIXTURES_LOADING";
export const INTERNATIONAL = "INTERNATIONAL";
export const RANKING_LOADING = "RANKING_LOADING";
export const RANKING = "RANKING";
export const ALL = "ALL";
export const DOMESTIC = "DOMESTIC";
export const month_string = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEPT",
  "OCT",
  "NOV",
  "DEC",
];
export const play_status = {
  in_play: "LIVE",
  result: "COMPLETED",
  stumps: "STUMPS",
  lunch_break: "LUNCH BREAK",
  pre_match: "STARTING SOON",
  abandoned: "CANCELED",
  scheduled: "SCHEDULED",
  dinner_break: "DINNER BREAK",
  innings_break: "INNINGS BREAK",
  strategic_timeout: "LIVE",
  rain_delay: "RAIN DELAY",
  drinks_break: "LIVE",
};
export const noData = {
  OVR: "Over",
  RAIN: "Rain Interrupts Match",
  ST: "Stumped",
  TU: "Third Umpire",
  RE: "Review",
  DR: "Drink Break",
  LU: "Lunch Break",
  DIN: "Dinner Break",
  TEA: "Tea Break",
  IB: "Inning Break",
  DEL: "Match Delayed",
  WOF: "Wet Out Field",
  STO: "Strategic Time Out",
  FH: "Free Hit",
  NB: "No Ball",
  BR: "Ball Running",
};

export const CommonConstant = {
  wicket: {
    NA: "No Status",
    LBW: "Leg Before Wickets",
    CT: "Catch",
    BW: "Bowled",
    ST: "Stumped",
    RO: "Run Out",
    OT: "Others",
    NB: "No Ball",
    WD: "Wide Ball",
  },
};
