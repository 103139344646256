// import axios from "axios";
// import api from "firebase-tools/lib/api";
// import { PROJET_ID } from "../../Constant";

class apis {}
apis.getPosts = async () => {
  return fetch(`https://rnews-wp.devserapp.com/wp-json/wp/v2/posts`)
    .then((Res) => Res.json())
    .then((res) => {
      // console.log("res", res);
      // let news_local = [], update_local = []
      if (res.length > 0) {
        // res.map(e => {
        //     if (e.categories.includes(3)) {
        //         news_local.push(e)
        //     } else if (e.categories.includes(1)) {
        //         update_local.push(e)
        //     }
        // })

        // setnews(news_local)
        return res;
      }
    })
    .catch((err) => {
      console.log("err while fetching posts", err);
    });
};

apis.getMatchesFromBackend = async () => {
  return fetch(`https://rnews-api.devserapp.com/getmatches`)
    .then((response) => response.json())
    .then((json) => {
      if (json) {
        let data = [];
        json.matches.map((item) => {
          if (true) {
            data.push(item);
          }
        });

        return data;
      }
    })
    .catch((error) => {
      // setLoading(false);
      console.log("erro while fetching backend featured matches", error);
    });
};
apis.getMergedApi = async (data) => {
  return fetch("https://rnews-api.devserapp.com/mergedapi")
    .then((res) => res.json())
    .then((res) => {
      if (data?.length > 0) {
        var temp_data = [];
        for (var key in data) {
          let arr = res.find(
            (element) => element?.key.split("^")[0] === data[key]?.key
          );

          if (arr) {
            var temp = {
              ...data[key],
              client_api_key: arr?.key?.split("^")[1],
            };
            temp_data.push(temp);
          } else {
            var temp = {
              ...data[key],
              client_api_key: null,
            };
            temp_data.push(temp);
          }
        }
        var matches = temp_data.filter(
          (ele, ind) =>
            ind ===
            temp_data.findIndex(
              (elem) => elem.key === ele.key && elem.key === ele.key
            )
        );

        matches.map((i) => {
          let d = JSON.parse(localStorage.getItem(`${i?.client_api_key}`));

          if (d !== null && typeof i?.matchData !== "object") {
            var data = JSON.parse(localStorage.getItem(`${i?.client_api_key}`));
            var time = new Date();

            if (data?.ttl >= time.getTime()) {
              i["matchData"] = data;
            } else {
              i["matchData"] = null;
              localStorage.removeItem(`${i?.client_api_key}`);
            }

            console.log("GOT_DATA_FROM_LOCAL", i.matchData);
          }
        });

        if (matches.length > 0) {
          return matches;
        }
      }
    })
    .catch((err) => {
      console.log("err while setting client api key", err);
    });
};
apis.getAssociations = async () => {
  return fetch(
    "https://rnews-api-database.devserapp.com/cricket-api/associations",
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((res) => res.json())
    .then((res) => res?.data)
    .catch((err) => console.log(err));
};
apis.getAssociationsFeaturedTournaments = async (association_key) => {
  return fetch(
    `https://rnews-api-database.devserapp.com/cricket-api/tournaments/${association_key}`
  )
    .then((res) => res.json())
    .then((res) => res?.data)
    .catch((err) => console.log(err));
};
apis.getTournamentFeaturedMatches = async (tournament_key) => {
  return fetch(
    `https://rnews-api-database.devserapp.com/cricket-api/tournaments-featuredmatches/${tournament_key}`
  )
    .then((res) => res.json())
    .then((res) => res?.data)
    .catch((err) => console.log(err));
};
apis.getRanks = async () => {
  return fetch("https://rnews-wp.devserapp.com/wp-json/wp/v2/ranking")
    .then((res) => res.json())
    .then((res) => {
      console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};
apis.getBreakingNews = async () => {
  return fetch("https://rnews-wp.devserapp.com/wp-json/wp/v2/breaking_news")
    .then((res) => res.json())
    .then((res) => {
      console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};
apis.getHomeBannerSlider = async () => {
  return fetch(
    "https://rnews-wp.devserapp.com/wp-json/wp/v2/home_banner_slider"
  )
    .then((res) => res.json())
    .then((res) => {
      console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};
apis.getTestimonials = async () => {
  return fetch("https://rnews-wp.devserapp.com/wp-json/wp/v2/testimonials")
    .then((res) => res.json())
    .then((res) => {
      // console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};
apis.getBottomTeamSlider = async () => {
  return fetch(
    "https://rnews-wp.devserapp.com/wp-json/wp/v2/bottom_team_slider"
  )
    .then((res) => res.json())
    .then((res) => {
      // console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};
apis.getSidebarads = async () => {
  return fetch("https://rnews-wp.devserapp.com/wp-json/wp/v2/sidebar_ads")
    .then((res) => res.json())
    .then((res) => {
      // console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};
apis.getRankingSidebarads = async () => {
  return fetch("https://rnews-wp.devserapp.com/wp-json/wp/v2/ranking_sidebar")
    .then((res) => res.json())
    .then((res) => {
      // console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};
apis.getTrendingTweets = async () => {
  return fetch("https://rnews-wp.devserapp.com/wp-json/wp/v2/trending")
    .then((res) => res.json())
    .then((res) => {
      // console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};
apis.getVideos = async () => {
  return fetch("https://rnews-wp.devserapp.com/wp-json/wp/v2/youtube_videos")
    .then((res) => res.json())
    .then((res) => {
      // console.log("rank res", res);
      return res;
    })
    .catch((err) => console.log(err));
};

export default apis;
