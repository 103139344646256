import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
// import "../../Components/Schedule/Scheduled.scss";
import "../../Components/TournamentMatches/TournamentMatches.scss";
import "../../Components/Rank/Rank.scss";
import International from "../International/International";
import Domestic from "../Domestic/Domestic";
import { useDispatch, useSelector } from "react-redux";
import apis from "../apis";
import {
  ASSOCIATIONS,
  ASSOCIATION_LOADING,
  month_string,
  TOURNAMENTS,
  TOURNAMENTS_FIXTURES,
  TOURNAMENTS_FIXTURES_LOADING,
  TOURNAMENTS_LOADING,
} from "../../Constant";
import Layout from "../Layout/Layout";
import TournamentMatchItem from "../TournamentMatchItem";
import {
  Redirect,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import loader from "../../Images/loader.gif";
import moment from "moment";

const TournamentMatches = () => {
  const [ScheduledTab, setScheduledTab] = useState(true);
  const [ResultsTab, setResultsTab] = useState(false);
  // const [associations, setAssociations] = useState();
  const [tournamentName, setTournamentName] = useState("Tournament");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [item, setItem] = useState([]);
  const dataRedux = useSelector((state) => state);
  console.log(dataRedux);
  const dispatch = useDispatch();
  const params = useParams();
  console.log(params);
  console.log(item);
  useEffect(() => {
    setItem(dataRedux?.CommonState?.TOURNAMENTS_FIXTURES);
  }, [dataRedux?.CommonState?.TOURNAMENTS_FIXTURES]);
  useEffect(() => {
    setIsLoading(
      dataRedux?.CommonState?.LOADER?.ASSOCIATION_LOADING ||
        dataRedux?.CommonState?.LOADER?.TOURNAMENTS_LOADING ||
        dataRedux?.CommonState?.LOADER?.TOURNAMENTS_FIXTURES_LOADING
    );
  }, [
    dataRedux?.CommonState?.LOADER?.ASSOCIATION_LOADING,
    dataRedux?.CommonState?.LOADER?.TOURNAMENTS_LOADING,
    dataRedux?.CommonState?.LOADER?.TOURNAMENTS_FIXTURES_LOADING,
  ]);
  useEffect(() => {
    var association_key = dataRedux?.CommonState?.ASSOCIATIONS?.filter(
      (i) =>
        i?.code?.toLowerCase()?.trim() ===
          params?.association_name?.toLowerCase()?.trim() && i?.code !== null
    )[0]?.key;
    if (dataRedux?.CommonState?.ASSOCIATIONS?.length > 0 && !association_key) {
      return history.replace("/not-found");
    }
    if (association_key) {
      getTournament(association_key);
    }
    // console.log("association_key", association_key);
  }, [dataRedux?.CommonState?.ASSOCIATIONS]);
  useEffect(() => {
    var tournament_key = dataRedux?.CommonState?.TOURNAMENTS?.filter(
      (i) =>
        i?.short_name?.split(" ")?.join("-").toLowerCase()?.trim() ===
          params?.tournament_name?.toLowerCase()?.trim() &&
        i?.short_name &&
        i?.short_name !== null
    )[0]?.key;
    setTournamentName(
      dataRedux?.CommonState?.TOURNAMENTS?.filter(
        (i) =>
          i?.short_name?.split(" ")?.join("-").toLowerCase()?.trim() ===
            params?.tournament_name?.toLowerCase()?.trim() &&
          i?.short_name &&
          i?.short_name !== null
      )[0]?.name
    );
    if (dataRedux?.CommonState?.TOURNAMENTS?.length > 0 && !tournament_key) {
      return history.replace("/not-found");
    }
    if (tournament_key) {
      getTournamentFeaturedMatches(tournament_key);
    }
    // console.log("tournament_key", tournament_key);
  }, [dataRedux?.CommonState?.TOURNAMENTS]);

  const handleInternationalClick = () => {
    setScheduledTab(true);
    setResultsTab(false);
  };
  const handleDomesticClick = () => {
    setScheduledTab(false);
    setResultsTab(true);
  };

  const getTournament = (key) => {
    dispatch({
      type: TOURNAMENTS_LOADING,
      payload: true,
    });
    apis
      .getAssociationsFeaturedTournaments(key)
      .then((res) => {
        dispatch({
          type: TOURNAMENTS,
          payload: res?.tournaments,
        });
        dispatch({
          type: TOURNAMENTS_LOADING,
          payload: false,
        });
      })
      .catch((err) => console.log(err));
  };
  const getTournamentFeaturedMatches = (key) => {
    apis
      .getTournamentFeaturedMatches(key)
      .then((res) => {
        dispatch({
          type: TOURNAMENTS_FIXTURES,
          payload: res?.matches?.sort((x, y) => {
            if (
              res?.intelligent_order?.indexOf(x?.key) <
              res?.intelligent_order?.indexOf(y?.key)
            ) {
              return -1;
            } else {
              return 1;
            }
          }),
        });
        dispatch({
          type: TOURNAMENTS_FIXTURES_LOADING,
          payload: false,
        });
      })
      .catch((err) =>
        console.log("err in fetching fixtures of tournament", err)
      );
  };
  if (isLoading) {
    return (
      <section className="loaderimage">
        <img src={loader} alt="Loading..." />
      </section>
    );
  }

  var getMonthandYear = (item) => {
    // console.log(item);
    var date = moment.unix(new Date(item)).format("ll");
    var m = month_string[moment(date, "ll")?.month()];
    var y = moment(date, "ll")?.year();
    console.log("date", m + " " + y);

    // var m = date;

    return m + " " + y;
  };
  var inter_items = item?.map((i) => {
    // console.log(i?.start_at)
    i["group_date"] = getMonthandYear(+i?.start_at);
    return i;
  });
  // console.log(inter_items)
  var curDate = new Date();
  const groupBy = (array, key) => {
    // Return the reduced array
    return array?.reduce((result, currentItem) => {
      // If an array already present for key, push it to the array. Otherwise create an array and push the object.
      (result[currentItem[key]] = result[currentItem[key]] || []).push(
        currentItem
      );
      // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
      return result;
    }, {}); // Empty object is the initial value for result object
  };

  // if(item?.length<1){
  //   return <div>
  //     No Matches Available!
  //   </div>
  // }
  // console.log(ScheduledTab)

  return (
    <section className="schedule-page cmn-padding">
      <div className="container">
        <div className="sec-title">
          <h2>{tournamentName}</h2>
        </div>
        <div className="tab-wrap">
          <div className="tab-links tranparent">
            <Button
              className={ScheduledTab ? "active" : "disable"}
              btnEvent={handleInternationalClick}
              btnText={"Scheduled"}
            />
            <Button
              className={ResultsTab ? "active" : "disable"}
              btnEvent={handleDomesticClick}
              btnText={"Results"}
            />
          </div>
          <div className="tournament-cover">
            {item?.filter((i) =>
              ScheduledTab
                ? i?.status === "not_started"
                : i?.status === "completed"
            )?.length < 1 && (
              <div className="center">No Matches Available!</div>
            )}

            {inter_items?.filter((i) =>
              ScheduledTab
                ? i?.status === "not_started"
                : i?.status === "completed"
            ) &&
              Object.keys(
                groupBy(
                  inter_items?.filter((i) =>
                    ScheduledTab
                      ? i?.status === "not_started"
                      : i?.status === "completed"
                  ),
                  "group_date"
                )
              )
                ?.sort((x, y) => {
                  if (+x?.split(" ")[1] > +y?.split(" ")[1]) {
                    return -1;
                  } else if (+x?.split(" ")[1] == +y?.split(" ")[1]) {
                    if (
                      month_string?.indexOf(x?.split(" ")[0]) >
                      month_string?.indexOf(y?.split(" ")[0])
                    ) {
                      return -1;
                    } else {
                      return 1;
                    }
                  } else {
                    return +1;
                  }
                })
                ?.map((item, index) => {
                  return (
                    <div key={index} className="tournaments-raw">
                      <div className="tournaments-month">{item}</div>
                      <div className="tournaments-details">
                        <ul>
                          {inter_items
                            ?.filter(
                              (i) =>
                                i?.group_date === item &&
                                (ScheduledTab
                                  ? i?.status === "not_started"
                                  : i?.status === "completed")
                            )
                            ?.sort((x, y) => {
                              if (x?.start_at && !y?.start_at) {
                                return 1;
                              } else if (!x?.start_at && y?.start_at) {
                                return -1;
                              } else if (
                                Math.abs(x?.start_at - curDate?.getTime()) <
                                Math.abs(y?.start_at - curDate?.getTime())
                              ) {
                                return 1;
                              } else {
                                return -1;
                              }
                            })
                            ?.map((i, index) => {
                              return (
                                <TournamentMatchItem item={i} key={index} />
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TournamentMatches;
