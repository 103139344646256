import React from "react";
import Logo from "../Logo/Logo";
import Social from "../Social/Social";
import Data from "../../Data/data.json";
import AppIcon from "../AppIcon.js/AppIcon";
import Nav from "../Nav/Nav";
import "./Footer.scss";

const Footer = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <footer className="footerSection cmn-padding">
        <div className="container">
          <div className="footerWrap">
            <Nav />
            <div className="footer-description">
              <div className="footer-logo">
                <Logo />
              </div>
              <p className="description">{Data.FooterDescription.Paragraph}</p>
            </div>
            <AppIcon />
            <Social />
            <p className="copyright">{Data.Copyright.CopyrightText}</p>
            <div className="scrolltotop">
              <button onClick={scrollTop}>Top</button>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
