import React from 'react'
import Layout from '../../Components/Layout/Layout'

const Error = () => {
    return (
        <>
          <Layout>
              <h1>OPPS ! PAGE NOT FOUND</h1>
          </Layout>
        </>
    )
}

export default Error
