import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CATEGORIES_POST,
  inningOrder,
  NEWS_LOADING,
  team1,
  team2,
} from "../../Constant";
import Data from "../../Data/data.json";
import apis from "../apis";
import Button from "../Button/Button";
import ScoreCardSidebar from "../ScoreCardSidebar";

const ScoreCard = ({ item }) => {
  const scoreCardData = Data.ScoreBoard[0].tabList[0].tabList[0];

  const [con1Tab, setcon1InfoTab] = useState(true);
  const [con2Tab, setcon2Tab] = useState(false);
  const dataRedux = useSelector((state) => state);
  console.log(dataRedux);
  const dispatch = useDispatch();

  // const [inning, setInning] = useState("a_1");
  const [team, setTeam] = useState(
    team1(item)?.split("_")[0]
      ? team1(item)?.split("_")[0]
      : item?.play?.first_batting
      ? item?.play?.first_batting
      : "a"
  );
  const [news, setNews] = useState();
  let digit = ["", "st", "nd", "rd", "th", "th", "th", "th", "th", "th", "th"];
  // useEffect(() => {
  //   setNews(dataRedux?.CommonState?.CATEGORIES_POST);
  // }, [dataRedux?.CommonState?.CATEGORIES_POST]);

  // useEffect(() => {
  //   getPosts();
  // }, []);
  // const getPosts = () => {
  //   apis
  //     .getPosts()
  //     .then((res) => {
  //       console.log("blog response", res);
  //       // setItem(res);
  //       dispatch({
  //         type: CATEGORIES_POST,
  //         payload: res,
  //       });
  //       dispatch({
  //         type: NEWS_LOADING,
  //         payload: false,
  //       });
  //     })
  //     .catch((err) => console.log("err setting posts", err));
  // };

  const handlecon1Click = () => {
    setcon1InfoTab(true);
    setcon2Tab(false);
    setTeam(team1(item)?.split("_")[0]);
  };
  const handlecon2Click = () => {
    setcon2Tab(true);
    setcon1InfoTab(false);
    setTeam(team2(item)?.split("_")[0]);
  };

  // const base_array = (item) => {
  //   return item?.play?.innings != undefined ? item?.play?.innings_order : [];
  // };
  // let digit = ["", "st", "nd", "rd", "th", "th", "th", "th", "th", "th", "th"];

  // const array_1 = (item) => {
  //   return item?.play?.innings != undefined
  //     ? Object.keys(item?.play?.innings)
  //     : [];
  // };

  // const inningOrder = (item) => {
  //   return [...new Set([...base_array(item), ...array_1(item)])];
  // };

  // const team1 = (item) => {
  //   return inningOrder(item).length >= 2
  //     ? inningOrder(item)[inningOrder(item).length - 2]
  //     : "a_1";
  // };
  // const team2 = (item) => {
  //   return inningOrder(item).length >= 1
  //     ? inningOrder(item)[inningOrder(item).length - 1]
  //     : "b_1";
  // };
  console.log(inningOrder(item)?.filter((i) => i?.split("_")[0] === team));

  return (
    <div className="tab-wrap scorecard-wrap">
      <div className="tab-links tranparent">
        {item?.play?.innings_order?.filter(
          (i) => i?.split("_")[0] === team1(item)?.split("_")[0]
        )?.length > 0 && (
          <Button
            className={con1Tab ? "active" : "disable"}
            btnEvent={handlecon1Click}
            btnText={item?.teams[team1(item)?.split("_")[0]]?.code}
          />
        )}
        {item?.play?.innings_order?.filter(
          (i) => i?.split("_")[0] === team2(item)?.split("_")[0]
        )?.length > 0 && (
          <Button
            className={con2Tab ? "active" : "disable"}
            btnEvent={handlecon2Click}
            btnText={item?.teams[team2(item)?.split("_")[0]]?.code}
          />
        )}
      </div>
      {/* <div className="tab-links tranparent">
        {inningOrder(item)
          ?.filter((i) => i?.split("_")[0] === team)
          ?.map((i) => {
            return (
              <Button
                className={inning == i ? "active" : "disable"}
                // btnEvent={() => {
                //   setInning(i);
                // }}
                btnText={i?.split("_")[1] + digit[i?.split("_")[1]] + " INNING"}
              />
            );
          })}
      </div> */}
      {item?.play?.innings_order &&
        inningOrder(item)
          ?.filter(
            (i) =>
              i?.split("_")[0] === team &&
              item?.play?.innings_order?.indexOf(i) > -1
          )
          ?.map((inning, index) => (
            <div key={index}>
              {" "}
              <div className="tab-links tranparent">
                <Button
                  className={
                    inningOrder(item)?.filter((i) => i?.split("_")[0] === team)
                      ?.length > 1
                      ? "active"
                      : "inning-disable"
                  }
                  // btnEvent={() => {
                  //   setInning(i);
                  // }}
                  btnText={
                    inning?.split("_")[1] +
                    digit[inning?.split("_")[1]] +
                    " INNING"
                  }
                />
              </div>
              <div className="tab-content">
                <div className="result-tab-content">
                  <div className="result-tab-left">
                    <div className="overflow">
                      <table className="table1">
                        <thead>
                          <tr>
                            <td>Batsman</td>
                            <td>R</td>
                            <td>B</td>
                            <td>4S</td>
                            <td>6S</td>
                            <td>S/R</td>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.play?.innings[inning]?.batting_order &&
                            item?.play?.innings[inning]?.batting_order.map(
                              (e, index) => (
                                <tr key={index}>
                                  <td>
                                    <span>
                                      {item?.players[e]?.player?.name}
                                      {e == item?.play?.live?.striker_key
                                        ? "**"
                                        : e == item?.play?.live?.non_striker_key
                                        ? "*"
                                        : ""}
                                    </span>
                                    <span className="batsman-out-details">
                                      {item?.players[e]?.score[
                                        inning?.split("_")[1]
                                      ]?.batting?.dismissal?.msg
                                        ? item?.players[e]?.score[
                                            inning?.split("_")[1]
                                          ]?.batting?.dismissal?.msg
                                        : e === item?.play?.live?.striker_key
                                        ? "on Strike"
                                        : e ===
                                          item?.play?.live?.non_striker_key
                                        ? "non-striker"
                                        : ""}
                                    </span>
                                  </td>

                                  <td>
                                    {
                                      item?.players[e]?.score[
                                        inning?.split("_")[1]
                                      ]?.batting?.score?.runs
                                    }
                                  </td>
                                  <td>
                                    {
                                      item?.players[e]?.score[
                                        inning?.split("_")[1]
                                      ]?.batting?.score?.balls
                                    }
                                  </td>
                                  <td>
                                    {
                                      item?.players[e]?.score[
                                        inning?.split("_")[1]
                                      ]?.batting?.score?.fours
                                    }
                                  </td>
                                  <td>
                                    {
                                      item?.players[e]?.score[
                                        inning?.split("_")[1]
                                      ]?.batting?.score?.sixes
                                    }
                                  </td>
                                  <td>
                                    {
                                      item?.players[e]?.score[
                                        inning?.split("_")[1]
                                      ]?.batting?.score?.strike_rate
                                    }
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="overflow">
                                          <table className='withfooter table2'>
                                              <thead className='custom-title'>
                                                  <td colspan="4">{scoreCardData.tabsList[0].tablesList[0].tablesHeading[2].tableTitle}</td>
                                              </thead>
                                              <thead>
                                                  <td>{scoreCardData.tabsList[0].tablesList[0].tablesHeading[2].otherTitles[0].tableTitle}</td>
                                                  <td>{scoreCardData.tabsList[0].tablesList[0].tablesHeading[2].otherTitles[1].tableTitle}</td>
                                                  <td>{scoreCardData.tabsList[0].tablesList[0].tablesHeading[2].otherTitles[2].tableTitle}</td>
                                                  <td>{scoreCardData.tabsList[0].tablesList[0].tablesHeading[2].otherTitles[3].tableTitle}</td>
                                              </thead>
                                              {
                                                  scoreCardData.tabsList[0].tablesList[0].tablesHeading[2].tableData.map((item, index) => (
                                                      <tr>
                                                          <td>{item.player}</td>
                                                          <td>{item.sixs}</td>
                                                          <td>{item.fours}</td>
                                                          <td>{item.runs}</td>
                                                      </tr>
                                                  ))
                                              }
                                              <tfoot>
                                                  <tr>
                                                      <td colSpan="4"><Link to="#">View Details</Link></td>
                                                  </tr>
                                              </tfoot>
                                          </table>
                                      </div> */}
                    <div className="overflow table3">
                      <table className="table3">
                        <thead>
                          <tr>
                            <td>Bowling</td>
                            <td>O</td>
                            <td>M</td>
                            <td>R</td>
                            <td>w</td>
                            <td>Eco</td>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.play?.innings[
                            inning.split("_")[0] === "a"
                              ? "b_" + inning.split("_")[1]
                              : "a_" + inning.split("_")[1]
                          ]?.bowling_order &&
                            item?.play?.innings[
                              inning.split("_")[0] === "a"
                                ? "b_" + inning.split("_")[1]
                                : "a_" + inning.split("_")[1]
                            ]?.bowling_order.map((e, index) => (
                              <tr key={index}>
                                <td>
                                  <span>
                                    {item?.players[e]?.player?.name}
                                    {e == item?.play?.live?.bowler_key
                                      ? "*"
                                      : ""}
                                  </span>
                                  <span className="bowler-type">
                                    {item?.players[
                                      e
                                    ]?.player?.bowling_style?.arm?.split(
                                      "_"
                                    )[0] +
                                      " " +
                                      item?.players[
                                        e
                                      ]?.player?.bowling_style?.arm?.split(
                                        "_"
                                      )[1] +
                                      " " +
                                      item?.players[e]?.player?.bowling_style
                                        ?.pace}
                                  </span>
                                </td>
                                {/* <td>
                             {item?.players[e]?.player?.bowling_style?.arm?.split(
                                                  "_"
                                                )[0] +
                                                  " " +
                                                  item?.players[e]?.player?.bowling_style?.arm?.split(
                                                    "_"
                                                  )[1] +
                                                  " " +
                                                  item?.players[e]?.player?.bowling_style?.pace} 
                            </td> */}
                                <td>
                                  {item?.players[e]?.score[
                                    inning?.split("_")[1]
                                  ]?.bowling?.score?.overs[0] +
                                    "." +
                                    item?.players[e]?.score[
                                      inning?.split("_")[1]
                                    ]?.bowling?.score?.overs[1]}
                                </td>
                                <td>
                                  {
                                    item?.players[e]?.score[
                                      inning?.split("_")[1]
                                    ]?.bowling?.score?.maiden_overs
                                  }
                                </td>
                                <td>
                                  {
                                    item?.players[e]?.score[
                                      inning?.split("_")[1]
                                    ]?.bowling?.score?.runs
                                  }
                                </td>
                                <td>
                                  {
                                    item?.players[e]?.score[
                                      inning?.split("_")[1]
                                    ]?.bowling?.score?.wickets
                                  }
                                </td>
                                <td>
                                  {
                                    item?.players[e]?.score[
                                      inning?.split("_")[1]
                                    ]?.bowling?.score?.economy
                                  }
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="overflow table4">
                                          <table className='withfooter table4'>
                                              <thead className='custom-title'>
                                                  <td colspan="4">{scoreCardData.tabsList[0].tablesList[0].tablesHeading[3].tableTitle}</td>
                                              </thead>
                                              <thead>
                                                  <td>{scoreCardData.tabsList[0].tablesList[0].tablesHeading[3].otherTitles[0].tableTitle}</td>
                                                  <td>{scoreCardData.tabsList[0].tablesList[0].tablesHeading[3].otherTitles[1].tableTitle}</td>
                                                  <td>{scoreCardData.tabsList[0].tablesList[0].tablesHeading[3].otherTitles[2].tableTitle}</td>
                                                  <td>{scoreCardData.tabsList[0].tablesList[0].tablesHeading[3].otherTitles[3].tableTitle}</td>
                                              </thead>
                                              {
                                                  scoreCardData.tabsList[0].tablesList[0].tablesHeading[3].tableData.map((item, index) => (
                                                      <tr>
                                                          <td>{item.player}</td>
                                                          <td>{item.sixs}</td>
                                                          <td>{item.fours}</td>
                                                          <td>{item.runs}</td>
                                                      </tr>
                                                  ))
                                              }
                                              <tfoot>
                                                  <tr>
                                                      <td colSpan="4"><Link to="#">View Details</Link></td>
                                                  </tr>
                                              </tfoot>
                                          </table>
                                      </div> */}
                  </div>
                  <div className="result-tab-right">
                    <ScoreCardSidebar news={news} />
                  </div>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

export default ScoreCard;
