import React from 'react'
import Image from "../Image/Image";
import Data from '../../Data/data.json'
import './Doodleanimation.scss';

const Doodlecricket = () => {

    return(
        <section className='doddlecricketsection'>
            <div className='planeanimation'>
                <Image src={window.location.origin + `/Images/${Data.Doodleanimation.image}`} />
            </div>
            <iframe src="https://doodlecricket.github.io" id="q" allowFullScreen frameBorder="0"  width ="100%" height ="600" scrolling="no" ></iframe>
            {/* <iframe src=" //www.google.com/logos/2012/football-2012-hp.html?hl=en-GB" id="q" allowFullScreen frameBorder="0"  width ="100%" height ="250"></iframe>
            <iframe src="//www.google.com/logos/2012/hurdles-2012-hp.html?hl=en-GB" id="q" allowFullScreen frameBorder="0"  width ="100%" height ="250"></iframe>
            <iframe src="//www.google.com/logos/2012/basketball-2012-hp.html?hl=en-GB" id="q" allowFullScreen frameBorder="0"  width ="100%" height ="250"></iframe> */}
            <div className='rdream-board'>
                 <Image src={window.location.origin + `/Images/${Data.Doodleanimation.rdreamboardimg}`} />
            </div>
        </section>
    )

} 

export default Doodlecricket