import React from 'react'
import './Runanimation.scss';
import Data from '../../Data/data.json';
import Image from "../Image/Image";


const Runanimation = () => {
return (
    <section className="runanimation-section">
        <div className="container">
            <div className="runanimation-cover"> 
                   <Image src={window.location.origin + `/Images/${Data.RunAnimation.runanimationimg}`} />
            </div>
        </div>
    </section>
  )
}

export default Runanimation