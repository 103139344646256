import React, { Component } from "react";
import Data from "../../Data/data.json";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../ScoreBoard/ScoreBoard.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  firebase_score_1,
  firebase_score_2,
  firebase_score_prev,
  inningOrder,
  MATCH_DETAIL_ID,
  socket_score_1,
  socket_score_2,
  team1,
  team2,
} from "../../Constant";
import moment from "moment";

const Stumps = ({ item }) => {
  const dispatch = useDispatch();
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    // autoplay: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const curDate = new Date();

  var title = (i) => {
    return `${i?.tournament?.name} | ${i?.sub_title}`;
  };

  return (
    <section className="score-slider-section">
      <Slider {...settings} className="score-slider-wrap center">
        {item
          ?.sort((x, y) => {
            if (
              Math.abs(x?.start_at - curDate.getTime()) <
              Math.abs(y?.start_at - curDate.getTime())
            ) {
              return 1;
            } else {
              return -1;
            }
          })
          ?.reverse()
          ?.map((i, index) => (
            <div className="score-slideBox">
              <h3>
                {title(i)?.length > 30
                  ? `${title(i)?.slice(0, 30)}...`
                  : title(i)}
              </h3>
              <Link
                key={index}
                onClick={() => {
                  dispatch({
                    type: MATCH_DETAIL_ID,
                    payload: i?.key,
                  });
                  // localStorage.setItem(
                  //   "MATCH_DETAIL_ID",
                  //   JSON.stringify(i?.key)
                  // );
                }}
                to={`/result/${`${i?.teams?.a?.code
                  ?.toLowerCase()
                  ?.trim()}_vs_${i?.teams?.b?.code
                  ?.toLowerCase()
                  ?.trim()}`}?_key=${Buffer.from(i?.key).toString("base64")}`}
              >
                <div className="score-box">
                  <h6>{`${moment
                    .unix(new Date(i?.start_at))
                    .format("lll")} (${i?.format?.toUpperCase()})`}</h6>
                  <div className="country-wrap">
                    <div className="country">
                      <h4>{i?.teams[team1(i)?.split("_")[0]]?.code}</h4>
                      <div className="stump-score">
                      {i?.play?.innings_order?.length > 2 && (
                        <h5>
                          {firebase_score_prev(
                            i,
                            inningOrder(i)?.filter(
                              (e) =>
                                e.split("_")[0] === team1(i)?.split("_")[0] &&
                                (e?.split("_")[1] < team1(i)?.split("_")[1] ||
                                  e?.split("_")[1] === "superover")
                            )
                          )}
                        </h5>
                      )}
                      {(!i?.matchData || i?.matchData === null) && (
                        
                          <h5>{firebase_score_1(i)}</h5>
                        
                      )}
                      </div>
                      {i?.matchData && i?.matchData !== null && (
                        <h5>{socket_score_1(i)}</h5>
                      )}
                    </div>
                    <div className="country">
                      <h4>{i?.teams[team2(i)?.split("_")[0]]?.code}</h4>
                      <div className="stump-score">
                      {i?.play?.innings_order?.length > 2 && (
                        <h5>
                          {firebase_score_prev(
                            i,
                            inningOrder(i)?.filter(
                              (e) =>
                                e.split("_")[0] === team2(i)?.split("_")[0] &&
                                (e?.split("_")[1] < team2(i)?.split("_")[1] ||
                                  e?.split("_")[1] === "superover")
                            )
                          )}
                        </h5>
                      )}
                      {(!i?.matchData || i?.matchData === null) && (
                        
                          <h5>{firebase_score_2(i)}</h5>
                        
                      )}
                      </div>
                      {i?.matchData && i?.matchData !== null && (
                        <h5>{socket_score_2(i)}</h5>
                      )}
                    </div>
                  </div>
                  <span className="result">
                    {/* {i?.play?.live?.score?.msg_trail_by} */}
                    {i?.matchData?.m?.length > 0
                      ? i?.matchData?.m?.length > 40
                        ? `${i?.matchData?.m?.slice(0, 40)}...`
                        : i?.matchData?.m
                      : i?.play?.live?.score?.msg_trail_by?.length > 0
                      ? i?.play?.live?.score?.msg_trail_by?.length > 40
                        ? `${i?.play?.live?.score?.msg_trail_by?.slice(
                            0,
                            40
                          )}...`
                        : i?.play?.live?.score?.msg_trail_by
                      : i?.play?.live?.score?.msg_lead_by?.length > 0
                      ? i?.play?.live?.score?.msg_lead_by?.length > 40
                        ? `${i?.play?.live?.score?.msg_lead_by?.slice(
                            0,
                            40
                          )}...`
                        : i?.play?.live?.score?.msg_lead_by
                      : i?.play?.live?.required_score?.title?.length > 0
                      ? i?.play?.live?.required_score?.title?.length > 40
                        ? `${i?.play?.live?.required_score?.title?.slice(
                            0,
                            40
                          )}...`
                        : i?.play?.live?.required_score?.title
                      : i?.status == "completed"
                      ? i?.play?.result?.msg?.length > 40
                        ? `${i?.play?.result?.msg?.slice(0, 40)}...`
                        : i?.play?.result?.msg
                      : i?.toss?.winner
                      ? `${
                          i?.teams[i?.toss?.winner]?.name
                        } won the toss and elected to ${i?.toss?.elected}`
                          .length > 40
                        ? `${`${
                            i?.teams[i?.toss?.winner]?.name
                          } won the toss and elected to ${
                            i?.toss?.elected
                          }`.slice(0, 40)}...`
                        : `${
                            i?.teams[i?.toss?.winner]?.name
                          } won the toss and elected to ${i?.toss?.elected}`
                      : i?.play_status === "pre_match"
                      ? "match starting soon"
                      : ""}
                  </span>
                  <div
                    className={
                      i?.status == "started" && i?.play_status !== "stumps"
                        ? "active"
                        : ""
                    }
                  ></div>
                </div>
              </Link>
            </div>
          ))}
      </Slider>
    </section>
  );
};
export default Stumps;
