import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
import "./Rank.scss";
import IccMen from "../IccMen/IccMen";
import IccWomen from "../IccWomen/IccWomen";
import Image from "../Image/Image";
import apis from "../apis";
import { useDispatch } from "react-redux";
import { RANKING, RANKING_LOADING } from "../../Constant";

const Rank = () => {
  const [menTab, setMenTab] = useState(true);
  const [womenTab, setWomenTab] = useState(false);
  const [item, setItem] = useState();
  const dispatch=useDispatch();

  const handleMenClick = () => {
    setMenTab(true);
    setWomenTab(false);
  };
  const handleWomenClick = () => {
    setWomenTab(true);
    setMenTab(false);
  };
  useEffect(() => {
   
    apis
      .getRanks()
      .then((res) => {
        // console.log(res[0]);
        setItem(res[0]);
        dispatch({
            type : RANKING,
            payload : res[0]?.acf
        })
        dispatch({
            type : RANKING_LOADING,
            payload : false
        })
      })
      .catch((err) => console.log("err while fetching ranks", err));
  }, []);

  return (
    <>
      <section className="Rank-section cmn-padding">
        <div className="container">
          <div className="rank-wrapper">
            <div className="rank-left">
              <div className="sec-title">
                <h2>Batsmen Ranking</h2>
              </div>
              <div className="tab-wrap">
                
                <div className="tab-content">
                  <IccMen item={item?.acf} />
                 
                </div>
              </div>
            </div>
            <div className="rank-right">
              <div className="rank-advertise">
                <div className="rank-img-content">
                  <Image className="rank-img"
                    src={
                      `/Images/${Data.RankRight.ranktelegramimg}`
                    }
                  />
                  {/* <div className="rank-logo">
                      <Image className="rank-img"
                        src={
                          `/Images/${Data.RankRight.ranklogoimg}`)
                            .default
                        }
                      />
                  </div> */}
                  <a
                    href="https://t.me/rDreamFair_official"
                    target="blank"
                    className="telegram"
                  >
                    Join us on Telegram
                  </a>
                </div>
                <div className="rank-img-content">
                  <Image
                    src={
                      `/Images/${Data.RankRight.rankinstgramimg}`
                    }
                  />
                  {/* <div className="rank-logo">
                      <Image className="rank-img"
                        src={
                          `/Images/${Data.RankRight.ranklogoimg}`)
                            .default
                        }
                      />
                  </div> */}
                  <a
                    href="https://www.instagram.com/rdreamfair_official/"
                    target="blank"
                    className="instagram"
                  >
                    Follow us On Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rank;
