import React from "react";
import Layout from "../../Components/Layout/Layout";
import Rank from "../../Components/Rank/Rank";
// import Slider from "../../Components/Slider/Slider";
import News from "../../Components/News/News";
import Youtubebanner from "../../Components/Youtubebanner/Youtubebanner";
import ScoreBoard from "../../Components/ScoreBoard/ScoreBoard";
import Breakkingnews from "../../Components/Breakingnews/Breakingnews";
import Twitter from "../../Components/Twitter/Twitter";
import { useSelector } from "react-redux";
import Youtubesection from "../../Components/Youtubesection/Youtubesection";
import Doodlecricket from "../../Components/Doodlecricket/Doodlecricket";
import Tournaments from "../../Components/Tournaments/Tournaments";
import Testimonials from "../../Components/Slider/Slider";
import PosterWeb from "../../Components/PosterWeb";
import PosterMobile from "../../Components/PosterMobile";
import MoneyAnimation from "../../Components/MoneyAnimation";
import TrendingTwitt from "../../Components/TrendingTwitt/TrendingTwitt";


const Home = () => {
  const dataRedux = useSelector((state) => state);
  console.log(dataRedux);
  return (
    <>
      <Layout>
        <Breakkingnews />
        <ScoreBoard />
        <PosterWeb />
        <PosterMobile />
        <Youtubebanner />
        <News />
        <MoneyAnimation/>

        <Doodlecricket />
        <Tournaments />

        <Twitter />
        <TrendingTwitt />
        <Youtubesection />
        <Rank />
        <Testimonials />
        {/* <Rank/> */}
      </Layout>
    </>
  );
};

export default Home;
