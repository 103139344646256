import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Data from "../../Data/data.json";
import "./Commentary.scss";
import ScoreCardSidebar from "../ScoreCardSidebar";

const Commentry = ({ item }) => {
  const matchInfoData = Data.ScoreBoard[0].tabList[0].tabList[1];
  const [inning, setInning] = useState("a_1");
  function rawMarkup(rawMarkup) {
    return { __html: rawMarkup };
  }
  useEffect(() => {
    setInning(item?.play?.innings_order[item?.play?.innings_order?.length - 1]);
  }, []);

  return (
    <div className="commentry-cover">
      <div className="commentry-cover-left">
        <div className="commentry-board">
          <div className="commentry-left">
            {/* <div className="end-detail">
              <div className="end-over">End of over {item?.matchData?}</div>
              <div className="end-run">
                <div className="end-total-run"><h6>11 Runs</h6></div>
                <div className="end-ball-details">
                  <ul>
                    {item?.matchData &&
                      item?.matchData?.o?.map((i) => {
                        if (i?.e === "NA" && i?.w === "NA" && i?.r === "NA") {
                          return;
                        }
                        return (
                          <li>
                            {i?.e !== "NA"
                              ? i?.e
                              : i?.w !== "NA"
                              ? i?.w
                              : i?.r !== "NA"
                              ? i?.r
                              : ""}
                          </li>
                        );
                      })}
                   
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="end-batting-detail">
              {item?.play?.live?.striker_key && (
                <div className="striker-batsman-details">
                  <h6>
                    {item?.players[item?.play?.live?.striker_key]?.player
                      ?.name + " *"}
                  </h6>
                  <p>
                    {
                      item?.players[item?.play?.live?.striker_key]?.score[
                        inning?.split("_")[1]
                      ]?.batting?.score?.runs
                    }
                    <span>
                      {
                        item?.players[item?.play?.live?.striker_key]?.score[
                          inning?.split("_")[1]
                        ]?.batting?.score?.balls
                      }
                    </span>
                  </p>
                </div>
              )}
              {item?.play?.live?.non_striker_key && (
                <div className="striker-batsman-details">
                  <h6>
                    {" "}
                    {
                      item?.players[item?.play?.live?.non_striker_key]?.player
                        ?.name
                    }
                  </h6>
                  <p>
                    {
                      item?.players[item?.play?.live?.non_striker_key]?.score[
                        inning?.split("_")[1]
                      ]?.batting?.score?.runs
                    }
                    <span>
                      {
                        item?.players[item?.play?.live?.non_striker_key]?.score[
                          inning?.split("_")[1]
                        ]?.batting?.score?.balls
                      }
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="commentry-right">
            {item?.players[item?.play?.live?.bowler_key]?.player && (
              <div className="striker-bowler-details">
                <h6>
                  {item?.players[item?.play?.live?.bowler_key]?.player?.name}
                </h6>
                {item?.play?.live?.bowler_key && (
                  <p>
                    {item?.players[item?.play?.live?.bowler_key]?.score[
                      inning?.split("_")[1]
                    ]?.bowling?.score?.overs?.length > 1 &&
                      item?.players[item?.play?.live?.bowler_key]?.score[
                        inning?.split("_")[1]
                      ]?.bowling?.score?.overs[0] +
                        "." +
                        item?.players[item?.play?.live?.bowler_key]?.score[
                          inning?.split("_")[1]
                        ]?.bowling?.score?.overs[1] +
                        " - "}
                    {item?.players[item?.play?.live?.bowler_key]?.score[
                      inning?.split("_")[1]
                    ]?.bowling?.score?.maiden_overs &&
                      item?.players[item?.play?.live?.bowler_key]?.score[
                        inning?.split("_")[1]
                      ]?.bowling?.score?.maiden_overs + " - "}
                    {item?.players[item?.play?.live?.bowler_key]?.score[
                      inning?.split("_")[1]
                    ]?.bowling?.score?.runs + " - "}
                    {
                      item?.players[item?.play?.live?.bowler_key]?.score[
                        inning?.split("_")[1]
                      ]?.bowling?.score?.wickets
                    }
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        {/* ========================HTML======================== */}
        {item?.play?.related_balls &&
          item?.play?.live?.recent_overs?.map((e, index) => {
            // console.log("e", e);
            return (
              <div key={index}>
                <div className="commentry-over">
                  <h6>over {e?.overnumber}</h6>
                </div>
                {/* <div className={styles.subDiv}>
                            <span>Mackenxie harvey</span>
                            <span>71 (50)</span>
                        </div>
                        <div className={styles.subDiv}>
                            <span>Kack peter</span>
                            <span>71 (50)</span>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.title}>
                            <span>REN: 140/6</span>
                        </div>
                        <div className={styles.subDiv}>
                            <span>Mackenxie harvey</span>
                            <span>11 - 0 - 87 - 1</span>
                        </div>*/}
                <ul className="over-commentry">
                  {e?.ball_keys?.map((ball, index) => {
                    return (
                      <li key={index}>
                        <div className="over-content">
                          <div>
                            {item?.play?.related_balls[ball]?.overs[0] +
                              "." +
                              item?.play?.related_balls[ball]?.overs[1]}
                          </div>

                          {/* <div className={item?.play?.related_balls[ball]?.team_score?.is_wicket ? styles.wicketball : item?.play?.related_balls[ball]?.batsman?.is_dot_ball ? styles.dotball  : styles.bullet }><span >{item?.play?.related_balls[ball]?.batsman?.is_six ? '6' : item?.play?.related_balls[ball]?.batsman.runs}</span></div> */}

                          <div>
                            <span className="ball-cover">
                              {item?.play?.related_balls[ball]?.team_score
                                ?.is_wicket
                                ? "W"
                                : item?.play?.related_balls[ball]?.batsman
                                    ?.is_six
                                ? "6"
                                : item?.play?.related_balls[ball]?.batsman.runs}
                            </span>
                          </div>
                        </div>
                        <div className="ball-commentry">
                          {/* {item?.play?.related_balls[ball]?.comment}  */}
                          <span
                            dangerouslySetInnerHTML={rawMarkup(
                              item?.play?.related_balls[ball]?.comment
                            )}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        {/* <div className='commentry-more'>
           <button>Load More Cmmentry</button>  
      </div> */}
      </div>
      <div className="commentry-cover-right">
        <ScoreCardSidebar />
      </div>
    </div>
  );
};

export default Commentry;
