import {
  MATCH_DETAIL_ID,
  LOADER,
  MATCHES_LOADING,
  NEWS_LOADING,
  NEWS_DETAIL,
  RELATED_POST,
  CATEGORIES_POST,
  FEATURED_MATCHES,
  FIREBASE_MATCHES,
  SOCKET_MATCHES,
  TOURNAMENTS,
  ASSOCIATIONS,
  ASSOCIATION_LOADING,
  TOURNAMENTS_LOADING,
  TOURNAMENTS_FIXTURES,
  TOURNAMENTS_FIXTURES_LOADING,
  RANKING_LOADING,RANKING
} from "../Constant/index";

let initialState = {
  MATCH_DETAIL_ID: "",
  NEWS_DETAIL: "",
  RELATED_POST: "",
  CATEGORIES_POST: [],
  FEATURED_MATCHES: [],
  FIREBASE_MATCHES: [],
  SOCKET_MATCHES: [],
  ASSOCIATIONS: [],
  TOURNAMENTS: [],
  TOURNAMENTS_FIXTURES: [],
  RANKING: [],
  LOADER: {
    NEWS_LOADING: true,
    MATCHES_LOADING: true,
    ASSOCIATION_LOADING: true,
    TOURNAMENTS_LOADING: true,
    TOURNAMENTS_FIXTURES_LOADING: true,
    RANKING_LOADING: true,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MATCH_DETAIL_ID:
      return { ...state, MATCH_DETAIL_ID: action.payload };
    case NEWS_DETAIL:
      return { ...state, NEWS_DETAIL: action.payload };
    case RELATED_POST:
      return { ...state, RELATED_POST: action.payload };
    case CATEGORIES_POST:
      return { ...state, CATEGORIES_POST: action.payload };
    case FEATURED_MATCHES:
      return { ...state, FEATURED_MATCHES: action.payload };
    case FIREBASE_MATCHES:
      return { ...state, FIREBASE_MATCHES: action.payload };
    case SOCKET_MATCHES:
      return { ...state, SOCKET_MATCHES: action.payload };
    case TOURNAMENTS:
      return { ...state, TOURNAMENTS: action.payload };
    case TOURNAMENTS_FIXTURES:
      return { ...state, TOURNAMENTS_FIXTURES: action.payload };
    case ASSOCIATIONS:
      return { ...state, ASSOCIATIONS: action.payload };
    case RANKING:
      return { ...state, RANKING: action.payload };
    case NEWS_LOADING:
      return {
        ...state,
        LOADER: { ...state.LOADER, NEWS_LOADING: action.payload },
      };
    case MATCHES_LOADING:
      return {
        ...state,
        LOADER: { ...state.LOADER, MATCHES_LOADING: action.payload },
      };
    case ASSOCIATION_LOADING:
      return {
        ...state,
        LOADER: { ...state.LOADER, ASSOCIATION_LOADING: action.payload },
      };
    case TOURNAMENTS_LOADING:
      return {
        ...state,
        LOADER: { ...state.LOADER, TOURNAMENTS_LOADING: action.payload },
      };
    case TOURNAMENTS_FIXTURES_LOADING:
      return {
        ...state,
        LOADER: {
          ...state.LOADER,
          TOURNAMENTS_FIXTURES_LOADING: action.payload,
        },
      };
    case RANKING_LOADING:
      return {
        ...state,
        LOADER: { ...state.LOADER, RANKING_LOADING: action.payload },
      };

    default:
      return { ...state };
  }
}
