import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Runanimation from "../../Components/Runanimation/Runanimation";
import Appdownload from "../../Components/Appdownload/Appdownload";
import Teamslider from "../../Components/Teamslider/Teamslider";
import Brand from '../Brand/Brand';

const Layout = (props) => {
  return (
    <>
      <Header />
      <main className="page">
        {props.children}
      </main>
      <Runanimation />
      <Appdownload />
      <Teamslider />
      <Footer />
    </>
  );
};

export default Layout;
