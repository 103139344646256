import React from 'react'
import Layout from '../../Components/Layout/Layout'

const News = () => {
    return (
        <>
            <Layout>
                <h1>News</h1>
            </Layout>
        </>
    )
}

export default News
