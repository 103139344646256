import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Components/Layout/Layout";
import Score from "../../Components/Score/Score";
import { FEATURED_MATCHES } from "../../Constant";
import loader from "../../Images/loader.gif";
const ScoreInner = () => {
  const dataRedux = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(dataRedux?.CommonState?.LOADER?.MATCHES_LOADING);
  }, [dataRedux?.CommonState?.LOADER?.MATCHES_LOADING]);
  // console.log("MATCHES_LOADING", isLoading);
  console.log(dataRedux);
  const dispatch = useDispatch();
  const [item, setItem] = useState();
  // console.log("SCORE_INNER_ITEM", item);
  const queryParams = new URLSearchParams(window.location.search);
  let match_key = Buffer.from(queryParams.get("_key"), "base64").toString(
    "ascii"
  );

  //   ? null
  //   : Buffer.from(queryParams.get("_key"), "base64")
  //       .toString("ascii")
  //       .split("##")[1];
  console.log(match_key);

  useEffect(() => {
    if (dataRedux?.CommonState?.FEATURED_MATCHES?.length > 0) {
      setItem(
        dataRedux?.CommonState?.FEATURED_MATCHES?.filter(
          (i) => i?.key === match_key
        )[0]
      );
    } else {
      setItem(
        dataRedux?.CommonState?.FIREBASE_MATCHES?.filter(
          (i) => i?.key === match_key
        )[0]
      );
    }
  }, [dataRedux?.CommonState?.FEATURED_MATCHES]);
  useEffect(() => {
    setFeaturedMatches(
      dataRedux?.CommonState?.FIREBASE_MATCHES,
      dataRedux?.CommonState?.SOCKET_MATCHES
    );
  }, [
    dataRedux.CommonState.FIREBASE_MATCHES,
    dataRedux.CommonState.SOCKET_MATCHES,
  ]);
  const setFeaturedMatches = (firebase_matches, socket_matches) => {
    var matches = firebase_matches?.map((i) => {
      if (i?.client_api_key && i?.client_api_key === socket_matches?.e) {
        i["matchData"] = socket_matches;
      } else if (
        localStorage.getItem(`${i?.client_api_key}`) &&
        localStorage.getItem(`${i?.client_api_key}`) !== null
      ) {
        var data = JSON.parse(localStorage.getItem(`${i?.client_api_key}`));
        var time = new Date();
        if (data?.ttl >= time.getTime()){
          i["matchData"] = JSON.parse(
            localStorage.getItem(`${i?.client_api_key}`)
          );
        }else{
          localStorage.removeItem(`${i?.client_api_key}`)
        } 
        
       
      } else {
        i["matchData"] = null;
      }
      return i;
    });
    // console.log("matches",matches)
    dispatch({
      type: FEATURED_MATCHES,
      payload: matches,
    });
  };
  return (
    <>
      <Layout>
        {isLoading && (
          <section className="loaderimage">
            <img src={loader} alt="Loading..." />
          </section>
        )}
        {!isLoading && <Score item={item} />}
      </Layout>
    </>
  );
};

export default ScoreInner;
