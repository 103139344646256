import { useEffect, useState } from "react";
import apis from "../../Components/apis";
import Layout from "../../Components/Layout/Layout";
import YoutubeEmbed from "../../Components/YoutubeEmbed/YoutubeEmbed";
import '../Videos/Videos.scss';
import loader from "../../Images/loader.gif";


// https://www.googleapis.com/youtube/v3/playlistItems?key=API
const Videos = () => {
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    apis.getVideos().then((res) => {
      setItem(res[0]?.acf?.youtube_videos);
      setIsLoading(false)
    });
  }, []);
  return (
    <Layout>
      <div className="Video-section cnm-padding">
        <div className="container">
              <div className="sec-title">
                  <h2>VIDEOS</h2>
              </div>
               {isLoading && (
                  <section className="loaderimage">
                    <img src={loader} alt="Loading..." />
                  </section>
                )}
              <div className="video-cover">
                
                {!isLoading &&
                  item?.map((i) => (
                   
                      <YoutubeEmbed embedId={i?.video_item} />
                    
                  ))}
              </div>
        </div>
      </div>
    </Layout>
  );
};
export default Videos;
