import moment from "moment";
import ScoreCardSidebar from "../ScoreCardSidebar";
import {
  firebase_score_1,
  firebase_score_2,
  firebase_score_prev,
  inningOrder,
  //   MATCH_DETAIL_ID,
  socket_score_1,
  socket_score_2,
  team1,
  team2,
} from "../../Constant";
import "./AllFixturesMatchItem.scss";

// import Button from "../Button/Button";

const AllFixturesMatchItem = ({ item }) => {
  console.log(item);

  const scoreItem = (item) => {
    if (item?.status == "completed") {
      if (!item?.play && item?.play === null) {
        return;
      }
      // return (
      //   <div className="tournaments-time">
      //     <>
      //       <span>
      //         <h4>{item?.teams["a"]?.code}</h4>
      //         <h5>
      //           {
      //             item?.play?.innings[
      //               item?.play?.innings_order?.filter(
      //                 (i) => i?.split("_")[0] === "a"
      //               )[
      //                 item?.play?.innings_order?.filter(
      //                   (i) => i?.split("_")[0] === "a"
      //                 )?.length - 1
      //               ]
      //             ]?.score_str
      //           }
      //         </h5>
      //       </span>
      //       <span> vs </span>
      //       <span>
      //         <h4>{item?.teams["b"]?.code}</h4>
      //         <h5>
      //           {
      //             item?.play?.innings[
      //               item?.play?.innings_order?.filter(
      //                 (i) => i?.split("_")[0] === "b"
      //               )[
      //                 item?.play?.innings_order?.filter(
      //                   (i) => i?.split("_")[0] === "b"
      //                 )?.length - 1
      //               ]
      //             ]?.score_str
      //           }
      //         </h5>
      //       </span>
      //     </>
      //   </div>
      // );
    }
    if (item?.status == "not_started") {
      return <div>Scheduled</div>;
    }
    if (item?.status !== "not_started") {
      return (
        <div className="tournaments-time">
          <>
            <span>
              <h4>{item?.teams[team1(item)?.split("_")[0]]?.code}</h4>
              <>
                {item?.play?.innings_order?.length > 2 && (
                  <h5>
                    {firebase_score_prev(
                      item,
                      inningOrder(item)?.filter(
                        (e) =>
                          e.split("_")[0] === team1(item)?.split("_")[0] &&
                          (e?.split("_")[1] < team1(item)?.split("_")[1] ||
                            e?.split("_")[1] === "superover")
                      )
                    )}
                  </h5>
                )}
                {(!item?.matchData || item?.matchData === null) && (
                  <h5>{firebase_score_1(item)}</h5>
                )}
                {item?.matchData && item?.matchData !== null && (
                  <h5>{socket_score_1(item)}</h5>
                )}
              </>
            </span>
            <span> vs </span>
            <span>
              <h4>{item?.teams[team2(item)?.split("_")[0]]?.code}</h4>
              <>
                {item?.play?.innings_order?.length > 2 && (
                  <h5>
                    {firebase_score_prev(
                      item,
                      inningOrder(item)?.filter(
                        (e) =>
                          e.split("_")[0] === team2(item)?.split("_")[0] &&
                          (e?.split("_")[1] < team2(item)?.split("_")[1] ||
                            e?.split("_")[1] === "superover")
                      )
                    )}
                  </h5>
                )}
                {(!item?.matchData || item?.matchData === null) && (
                  <h5>{firebase_score_2(item)}</h5>
                )}
                {item?.matchData && item?.matchData !== null && (
                  <h5>{socket_score_2(item)}</h5>
                )}
              </>
            </span>
          </>
        </div>
      );
    }
  };

  //   if(item?.length<1){
  //       return<div>
  //           No Matches Available!
  //       </div>
  //   }
  return (
    <div className="score-box">
      <div className="upcomingMatch">
        <div className="dateHeader">
          <h5>
            {item?.short_name} | {item?.sub_title}
          </h5>
          <h6>{moment.unix(new Date(item?.start_at))?.format("lll")}</h6>
        </div>
        <div className="matchDetails">
          <div className="smatchTime">
            <span>
              {item?.venue?.name && item?.venue?.name !== null
                ? `At ${item?.venue?.name}`
                : ""}
            </span>
            <span className="lightText">
              {item?.venue?.city &&
              item?.venue?.city !== null &&
              item?.venue?.country?.name &&
              item?.venue?.country?.name !== null
                ? `${item?.venue?.city} , ${item?.venue?.country?.name}`
                : ""}
            </span>
          </div>
          <div className="smatchVenue">
            <h5>{scoreItem(item)}</h5>
            <h5 className="lightText">
              {item?.status === "completed" ? `${item?.play?.result?.msg}` : ""}
            </h5>
          </div>
        </div>
      </div>
      <div
        className={
          item?.status === "started" && item?.play_status !== "stumps"
            ? "active"
            : ""
        }
      ></div>
    </div>
  );
};
export default AllFixturesMatchItem;
