import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { useDispatch } from "react-redux";
import apis from "..";
import { firebaseConfig, onlyInLeft } from "../../../Constant";

export const clientkeyadder = async (snapshot_data, data) => {
  // console.log(snapshot_data,data)
  var only_Featured = data?.filter(
    (i) => !Object.keys(snapshot_data)?.includes(i?.key)
  );
  console.log("only featured", only_Featured);
  // console.log(snapshot_data)
  var only_firebase = [];
  Object.keys(snapshot_data).map((key) => {
    only_firebase.push(snapshot_data[key]);
  });
  console.log("only_firebaset", only_firebase);

  // var result = Object.keys(snapshot_data).map((key) => [
  //   key,
  //   snapshot_data[key],
  // ]);
  let live = [],
    other = [],
    schedule = [],
    liveMatch = [];
  // result.map((e) => {
  //   // console.log("firebase_match", e);
  //   live.push(e[1]);
  // });
  // const isSameUser = (a, b) => a.key == b.key && a.key == b.key;
  // const onlyInLeft = (left, right, compareFunction) =>
  // left.filter(
  //   (leftValue) =>
  //     !right.some((rightValue) => compareFunction(leftValue, rightValue))
  // );
  // const onlyInA = onlyInLeft(live, data, isSameUser);
  // const onlyInB = onlyInLeft(data, live, isSameUser);

  // const result1 = [...onlyInA, ...onlyInB];
  // // console.log('result1',result1)

  // let data1 = [...result1, ...data];
  var result = [...only_Featured, ...only_firebase];

  result.map((e) => {
    if (e.status == "not_started") {
      schedule.push(e);
    } else if (e.status == "started") {
      liveMatch.push(e);
    } else {
      other.push(e);
    }
  });

  let finalArry = [...liveMatch, ...schedule, ...other];
  var matches = await apis.getMergedApi(finalArry);
  return matches;
};
