import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
import Image from "../Image/Image";
import img from "../../Images/images";
import "./Tournaments.scss";
import { Link } from "react-router-dom";
import apis from "../apis";
import { useDispatch, useSelector } from "react-redux";
import {
  ASSOCIATIONS,
  ASSOCIATION_LOADING,
  month_string,
  TOURNAMENTS,
  TOURNAMENTS_LOADING,
} from "../../Constant";
import loader from "../../Images/loader.gif";
import moment from "moment";

const Tournaments = () => {
  const [internTab, setInternTab] = useState(true);
  const [leagTab, setLeagTab] = useState(false);
  const [associations, setAssociations] = useState([]);
  const [cur_association, setcur_association] = useState(
    internTab ? "c__board__icc__c2ab7ee61" : "c__board__bcci__b13f0"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isTournamentLoading, setIsTournamentLoading] = useState(true);
  // console.log("cur_association", cur_association);
  const priority = [
    "ECB",
    "SLC",
    "PCB",
    "BCB",
    "NZC",
    "CSA",
    "CWI",
    "CA",
    "BCCI",
    "ACC",
    "ICC",
  ];
  const dispatch = useDispatch();
  const dataRedux = useSelector((state) => state);
  const [tournaments, setTournaments] = useState([]);

  const handleInternClick = () => {
    setInternTab(true);
    setLeagTab(false);
    setcur_association("c__board__icc__c2ab7ee61");
    fetchTournaments("c__board__icc__c2ab7ee61");
  };
  const handleLeagClick = () => {
    setLeagTab(true);
    setInternTab(false);
    setcur_association("c__board__bcci__b13f0");
    fetchTournaments("c__board__bcci__b13f0");
  };
  useEffect(() => {
    setIsLoading(dataRedux?.CommonState?.LOADER?.ASSOCIATION_LOADING);
    setIsTournamentLoading(dataRedux?.CommonState?.LOADER?.TOURNAMENTS_LOADING);
  }, [
    dataRedux?.CommonState?.LOADER?.ASSOCIATION_LOADING,
    dataRedux?.CommonState?.LOADER?.TOURNAMENTS_LOADING,
  ]);
  useEffect(() => {
    fetchTournaments(cur_association);
  }, []);
  useEffect(() => {
    setAssociations(dataRedux?.CommonState?.ASSOCIATIONS);
  }, [dataRedux?.CommonState?.ASSOCIATIONS]);
  useEffect(() => {
    setTournaments(dataRedux?.CommonState?.TOURNAMENTS);
  }, [dataRedux?.CommonState?.TOURNAMENTS]);
  const fetchTournaments = (cur_association) => {
    dispatch({
      type: TOURNAMENTS_LOADING,
      payload: true,
    });
    apis
      .getAssociationsFeaturedTournaments(cur_association)
      .then((res) => {
        dispatch({
          type: TOURNAMENTS,
          payload: res?.tournaments,
        });
        dispatch({
          type: TOURNAMENTS_LOADING,
          payload: false,
        });
      })
      .catch((err) => console.log(err));
  };
  var inter_items = tournaments;
  var league_items = tournaments;
  var curDate = new Date();
  var getMonthandYear = (item) => {
    // console.log(item);
    var date = moment.unix(new Date(item)).format("ll");
    var m = month_string[moment(date, "ll")?.month()];
    var y = moment(date, "ll")?.year();
    // console.log("date", m + " " + y);

    // var m = date;

    return m + " " + y;
  };

  return (
    <>
      {isLoading && (
        <section className="loaderimage">
          <img src={loader} alt="Loading..." />
        </section>
      )}
      {!isLoading && (
        <section className="Results-section white-bg cmn-padding">
          <div className="container">
            <div className="sec-title">
              <h2 className="black-color">TOURNAMENTS</h2>
            </div>
            <div className="tab-wrap">
              <div className="tab-links">
                <Button
                  className={internTab ? "active" : "disable"}
                  btnEvent={handleInternClick}
                  btnText={Data.Results[0].tabText}
                  disabled={isLoading || isTournamentLoading}
                />
                <Button
                  className={leagTab ? "active" : "disable"}
                  btnEvent={handleLeagClick}
                  btnText={Data.Results[1].tabText}
                  disabled={isLoading || isTournamentLoading}
                />
              </div>
              {internTab && (
                <div className="tab-links">
                  {associations
                    ?.filter(
                      (i) =>
                        (!i?.country?.code || i?.country?.code === null) &&
                        i?.code !== "VG"
                    )
                    .sort((x, y) => {
                      if (
                        // priority?.indexOf(x?.code) > -1 &&
                        priority?.indexOf(x?.code) < priority?.indexOf(y?.code)
                      ) {
                        return 1;
                      } else {
                        return -1;
                      }
                    })
                    .map((item, index) => (
                      <Button
                        className={
                          cur_association === item?.key ? "active" : "disable"
                        }
                        btnEvent={() => {
                          setcur_association(item?.key);
                          // setIsLoading(true);
                          fetchTournaments(item?.key);
                        }}
                        btnText={
                          item?.country?.name ? item?.country?.name : item?.code
                        }
                        disabled={isLoading || isTournamentLoading}
                      />
                    ))}
                </div>
              )}
              {leagTab && (
                <div className="tab-links">
                  {associations
                    ?.filter(
                      (i) =>
                        i?.country?.code &&
                        i?.country?.code !== null &&
                        priority?.indexOf(i?.code) > -1
                    )
                    .sort((x, y) => {
                      if (
                        // priority?.indexOf(x?.code) > -1 &&
                        priority?.indexOf(x?.code) < priority?.indexOf(y?.code)
                      ) {
                        return 1;
                      } else {
                        return -1;
                      }
                    })
                    .map((item) => (
                      <Button
                        className={
                          cur_association === item?.key ? "active" : "disable"
                        }
                        btnEvent={() => {
                          setcur_association(item?.key);
                          // setIsLoading(true);
                          fetchTournaments(item?.key);
                        }}
                        btnText={
                          item?.country?.name ? item?.country?.name : item?.code
                        }
                      />
                    ))}
                </div>
              )}
              {isTournamentLoading && (
                <section className="loaderimage">
                  <img src={loader} alt="Loading..." />
                </section>
              )}
              {!isTournamentLoading && (
                <div className="tab-content">
                  {internTab &&
                    (inter_items?.length > 0 ? (
                      <ul className="content-list">
                        {inter_items
                          ?.sort((x, y) => {
                            if (x?.start_date && !y?.start_date) {
                              return 1;
                            } else if (!x?.start_date && y?.start_date) {
                              return -1;
                            } else if (
                              Math.abs(x?.start_date - curDate?.getTime()) <
                              Math.abs(y?.start_date - curDate?.getTime())
                            ) {
                              return 1;
                            } else {
                              return -1;
                            }
                          })
                          ?.reverse()
                          ?.map((item, index) => {
                            // console.log(item)
                            if (index > 8) {
                              return;
                            } else {
                              return (
                                <Link
                                  to={`/tournaments/${
                                    associations?.filter(
                                      (i) => i.key === item?.association_key
                                    )[0]?.code
                                  }/fixtures/${item?.short_name
                                    ?.split(" ")
                                    .join("-")}`}
                                >
                                  <li key={index} id={index}>
                                    <div className="country-box">
                                      <h4 className="score center">
                                        {item?.name}
                                      </h4>
                                      <div>
                                        <h5 className="opacity">
                                          {item?.is_date_confirmed
                                            ? moment
                                                .unix(
                                                  new Date(item?.start_date)
                                                )
                                                .format("ll")
                                            : ""}
                                        </h5>
                                        <h6>
                                          {item?.is_venue_confirmed
                                            ? item?.countries[0]?.name
                                            : ""}
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                </Link>
                              );
                            }
                          })}
                      </ul>
                    ) : (
                      <h3>"No Tournaments Available"</h3>
                    ))}
                  {leagTab &&
                    (league_items?.length > 0 ? (
                      <ul className="content-list">
                        {league_items
                          ?.sort((x, y) => {
                            if (x?.start_date && !y?.start_date) {
                              return 1;
                            } else if (!x?.start_date && y?.start_date) {
                              return -1;
                            } else if (
                              Math.abs(x?.start_date - curDate?.getTime()) <
                              Math.abs(y?.start_date - curDate?.getTime())
                            ) {
                              return 1;
                            } else {
                              return -1;
                            }
                          })
                          ?.map((item, index) => {
                            if (index > 8) {
                              return;
                            } else {
                              return (
                                <Link
                                  to={`/tournaments/${
                                    associations?.filter(
                                      (i) => i.key === item?.association_key
                                    )[0]?.code
                                  }/fixtures/${item?.short_name
                                    ?.split(" ")
                                    ?.join("-")}`}
                                >
                                  <li key={index} id={index}>
                                    <div className="country-box">
                                      <h4 className="score center">
                                        {item?.name}
                                      </h4>
                                      <div>
                                        <h5 className="opacity">
                                          {item?.is_date_confirmed
                                            ? moment
                                                .unix(
                                                  new Date(item?.start_date)
                                                )
                                                .format("ll")
                                            : ""}
                                        </h5>
                                        <h6>
                                          {item?.is_venue_confirmed
                                            ? item?.countries[0]?.name
                                            : ""}
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                </Link>
                              );
                            }
                          })}
                      </ul>
                    ) : (
                      <h3>"No Tournaments Available"</h3>
                    ))}
                  {/* {leagTab && (
                  <ul className="content-list">
                    {Data.Results[1].tabList.map((inneritem, index) => (
                      <li key={index} id={index}>
                        <div className="country-wrap">
                          <div className="country-box">
                            <h5>{inneritem.countryName1}</h5>
                            <div>
                              <h5 className="opacity">{inneritem.score1}</h5>
                              <h6>{inneritem.over1}</h6>
                            </div>
                          </div>
                          <div className="country-box">
                            <h5>{inneritem.countryName2}</h5>
                            <div>
                              <h5 className="opacity">{inneritem.score2}</h5>
                              <h6>{inneritem.over2}</h6>
                            </div>
                          </div>
                        </div>
                        <span className="score center">
                          {inneritem.result}
                          <Image src={img.ARROW_IMG} alt="Arrow Icon" />
                        </span>
                      </li>
                    ))}
                  </ul>
                )} */}
                </div>
              )}
              <div className="btnLink">
                <Link to="/tournaments">See All</Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Tournaments;
