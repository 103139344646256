import React from 'react'
import './Twitter.scss';
import Image from "../Image/Image";
import Data from "../../Data/data.json"


const Twitter = () => {
return (
    <section className="twitter-section cmn-padding">
        <div className="container">
           <div className='twittersection-cover'> 
                <div className='left-animation'>
                    <Image src={window.location.origin + `/Images/${Data.Twittersection.leftimage}`} />
                </div>
                <div className="twittercover"> 
                    <a href='https://twitter.com/rDreamFair' target='_blank'>
                        <div className="twittertext">
                            <h4>{Data.Twittersection.follwontwitter}</h4>
                        </div>
                        <div className="twitter-img">
                            <Image src={window.location.origin + `/Images/${Data.Twittersection.image}`} />
                        </div>
                        <div className="twitter-title">
                            <h5>{Data.Twittersection.twittertitlename}</h5>
                            <h6>{Data.Twittersection.twitterid}</h6>
                        </div>
                    </a>
                </div>
                <div className='right-animation'>
                     <div className='rightanimation-cover'>
                         <a href='https://www.rdreamfair.com/' target='_blank'>
                          <Image src={window.location.origin + `/Images/${Data.Twittersection.rightimage}`} /> 
                          <div className="contact-animation">
                                <Image  src={window.location.origin + `/Images/${Data.Twittersection.rightanimation}`} /> 
                          </div>
                         </a> 
                     </div>
                </div>
         </div>     
    </div>
    </section>
  )
}

export default Twitter