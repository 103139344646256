import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
import "../Rank/Rank.scss";
import { useSelector } from "react-redux";
import loader from "../../Images/loader.gif";
import { Link } from "react-router-dom";

const IccMen = ({ item }) => {
  const [format, setFormat] = useState("TEST");
  // const[type,setType]=useState("")
  var index = { team: ["Position", "Team", "Rating", "Points"] ,player:["Position","","Player","Team", "Rating"]};
  const [isLoading, setIsLoading] = useState(true);
  const dataRedux = useSelector((state) => state);

  // console.log(item);
  useEffect(() => {
    setIsLoading(dataRedux?.CommonState?.LOADER?.RANKING_LOADING);
  }, [dataRedux?.CommonState?.LOADER?.RANKING_LOADING]);

  if (isLoading) {
    return (
      <section className="loaderimage">
        <img src={loader} alt="Loading..." />
      </section>
    );
  }

  return (
    <>
      <div className="tab-links">
        <Button
          className={format === "TEST" ? "active" : "disable"}
          btnEvent={() => {
            setFormat("TEST");
          }}
          btnText={Data.iccMen[0].type}
        />
        <Button
          className={format === "ODI" ? "active" : "disable"}
          btnEvent={() => {
            setFormat("ODI");
          }}
          btnText={Data.iccMen[1].type}
        />
        <Button
          className={format === "T20" ? "active" : "disable"}
          btnEvent={() => {
            setFormat("T20");
          }}
          btnText={Data.iccMen[2].type}
        />
      </div>
      <div className="tab-content">
        <table>
          <thead>
            {index?.player?.map((item, index) => (
              <td key={index}>{item}</td>
            ))}
          </thead>
          {item?.batsman
            ?.filter((i) => i?.matchformat === format)
            ?.sort((x, y) => {
              if (+x?.team_rank < +y?.team_rank) {
                return -1;
              } else {
                return 1;
              }
            })
            ?.map((item, index) => {
              if (index > 4) {
                return;
              }
              return (
                <tr key={index}>
                  <td>{item?.batsman_rank}.</td>
                  <td><img src={item?.batsman_image?.url}/></td>
                  <td>{item?.batsman_name}</td>
                  <td>{item?.team_name}</td>
                  <td>{item?.batsman_rating}</td>
                </tr>
              );
            })}
        </table>

        <Link to='/ranking'>See all</Link>
      </div>
    </>
  );
};

export default IccMen;
