import './RecentBalls.scss'

const SocketRecentBalls = ({ item }) => {
  return (
    <div className="recent-over-section">
      <div className='last-over'>
        <span>Current Over : </span>
        <span>
          {" "}
          {item?.matchData?.o?.map((i) => {
            if (i?.e === "NA" && i?.w === "NA" && i?.r === "NA") {
              return;
            }
            return (
              <p
                className={
                  i?.w && i?.w !== "NA"
                    ? "wicket"
                    : i?.r?.toString() === "4"
                    ? "four"
                    : i?.r?.toString() == "6"
                    ? "six"
                    : ""
                }
              >
                {i?.e !== "NA"
                  ? i?.e
                  : i?.w && (i?.w !== "NA")
                  ? "W"
                  : i?.r !== "NA"
                  ? i?.r
                  : ""}
              </p>
            );
          })}
        </span>
      </div>
      {item?.matchData?.l?.split(" ")?.length > 0 && (
        <div className='last-over'>
          <span>Last Over : </span>
          <span>
            {" "}
            {item?.matchData?.l?.split(" ")?.map((i) => {
              return (
                <>
                  {i?.trim()?.length !== 0 && (
                    <p
                      className={
                        i?.toLowerCase() == "w"
                          ? "wicket"
                          : i?.toString() === "4"
                          ? "four"
                          : i?.toString() == "6"
                          ? "six"
                          : ""
                      }
                    >
                      {i}
                    </p>
                  )}
                </>
              );
            })}
          </span>
        </div>
      )}
    </div>
  );
};
export default SocketRecentBalls;
