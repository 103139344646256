import React, { useState } from "react";
import Data from "../../Data/data.json";
import "./Score.scss";
import Button from "../Button/Button";
import ScoreCard from "../ScoreCard/ScoreCard";
import Overview from "../Overview/Overview";
import Commentry from "../Commentary/Commentary";
import Squad from "../Squad/Squad";
import Image from "../Image/Image";
// import {} from "../../Constant";
import {
  CommonConstant,
  noData,
  play_status,
  firebase_score_1,
  firebase_score_2,
  firebase_score_prev,
  inningOrder,
  MATCH_DETAIL_ID,
  socket_score_1,
  socket_score_2,
  team1,
  team2,
} from "../../Constant";
import FirebaseRecentBalls from "../FirebaseRecentBalls";
import SocketRecentBalls from "../SocketRecentBalls";
import ScoreCardSidebar from "../ScoreCardSidebar";

const Score = ({ item }) => {
  const score_data = Data.ScoreBoard[0].tabList[0];
  console.log(item);
  let noData = {
    OVR: "Over",
    RAIN: "Rain Interrupts Match",
    ST: "Stumps",
    TU: "Third Umpire",
    RE: "Review",
    DR: "Drink Break",
    LU: "Lunch Break",
    DIN: "Dinner Break",
    TEA: "Tea Break",
    IB: "Inning Break",
    DEL: "Match Delayed",
    WOF: "Wet Out Field",
    STO: "Strategic Time Out",
    FH: "Free Hit",
    NB: "No Ball",
    BR: "Ball Running",
  };

  const [matchInfoTab, setmatchInfoTab] = useState(true);
  const [liveTab, setLiveTab] = useState(false);
  const [scoreCardTab, setscoreCardTab] = useState(false);
  const [squadTab, setSquadTab] = useState(false);
  const [news, setNews] = useState();

  const handlematchInfoClick = () => {
    setmatchInfoTab(true);
    setLiveTab(false);
    setscoreCardTab(false);
    setSquadTab(false);
  };
  const handleliveTabClick = () => {
    setmatchInfoTab(false);
    setLiveTab(true);
    setscoreCardTab(false);
    setSquadTab(false);
  };
  const handlescoreCardTabClick = () => {
    setmatchInfoTab(false);
    setLiveTab(false);
    setscoreCardTab(true);
    setSquadTab(false);
  };
  const handleSquadTabClick = () => {
    setmatchInfoTab(false);
    setLiveTab(false);
    setscoreCardTab(false);
    setSquadTab(true);
  };

  const socket_commentry = (item) => {
    if (noData[item?.matchData?.s]) {
      return noData[item?.matchData?.s];
    }
    if (
      item?.matchData?.o[item?.matchData?.o?.length - 1]?.w &&
      item?.matchData?.o[item?.matchData?.o?.length - 1]?.w != "NA"
    ) {
      return (
        <Image
          src={window.location.origin +`/Images/${Data.scoreanimation.wicketanimation}`}
          className="ballanimation-score"
        />
      );
    }
    if (
      item?.matchData?.o[item?.matchData?.o?.length - 1]?.r?.toString() === "6"
    ) {
      return (
        <Image
          src={window.location.origin +`/Images/${Data.scoreanimation.sixanimation}`}
          className="ballanimation-score"
        />
      );
    }
    if (
      item?.matchData?.o[item?.matchData?.o?.length - 1]?.r?.toString() === "4"
    ) {
      return (
        <Image
          src={window.location.origin +`/Images/${Data.scoreanimation.fouranimation}`}
          className="ballanimation-score"
        />
      );
    }
    return (
      <>
        {!item?.matchData?.r
          ? item?.matchData?.o.length == 0 &&
            item?.matchData?.o[item?.matchData?.o?.length - 1]?.r == undefined
            ? item?.play_status == "in_play"
              ? "OVER"
              : ""
            : `${
                noData[item?.matchData?.s]
                  ? noData[item?.matchData?.s]
                  : item?.matchData?.o.length != 0
                  ? item?.matchData?.o[item?.matchData?.o?.length - 1]?.r !=
                    undefined
                    ? item?.matchData?.o[item?.matchData?.o?.length - 1]?.r !=
                      "NA"
                      ? item?.matchData?.o[
                          item?.matchData?.o?.length - 1
                        ]?.r.toString()
                      : item?.matchData?.o[item?.matchData?.o?.length - 1]?.w !=
                        "NA"
                      ? "Wicket! " +
                        CommonConstant["wicket"][
                          item?.matchData?.o[item?.matchData?.o?.length - 1]?.w
                        ]
                      : item?.matchData?.o[item?.matchData?.o?.length - 1]?.e !=
                        "NA"
                      ? CommonConstant["wicket"][
                          item?.matchData?.o[item?.matchData?.o?.length - 1]?.e
                        ]
                      : ""
                    : ""
                  : ""
              }`
          : "Ball Running"}
      </>
    );
    // return !item?.matchData?.r
    //   ? item?.matchData?.o.length == 0 &&
    //     item?.matchData?.o[item?.matchData?.o?.length - 1]?.r == undefined
    //     ? ""
    //     : `${
    //         noData[item?.matchData?.s]
    //           ? noData[item?.matchData?.s]
    //           : item?.matchData?.o.length != 0
    //           ? item?.matchData?.o[item?.matchData?.o?.length - 1]?.r !=
    //             undefined
    //             ? item?.matchData?.o[item?.matchData?.o?.length - 1]?.r != "NA"
    //               ? item?.matchData?.o[item?.matchData?.o?.length - 1]?.r
    //               : item?.matchData?.o[item?.matchData?.o?.length - 1]?.w !=
    //                 "NA"
    //               ? "Wicket! " +
    //                 CommonConstant["wicket"][
    //                   item?.matchData?.o[item?.matchData?.o?.length - 1]?.w
    //                 ]
    //               : item?.matchData?.o[item?.matchData?.o?.length - 1]?.e !=
    //                 "NA"
    //               ? CommonConstant["wicket"][
    //                   item?.matchData?.o[item?.matchData?.o?.length - 1]?.e
    //                 ]
    //               : ""
    //             : ""
    //           : ""
    //       }`
    //   : "Ball Running";
  };
  function rawMarkup(rawMarkup) {
    return { __html: rawMarkup };
  }

  const firebase_commentry = (item) => {
    if (!(item?.play?.related_balls && item?.play?.live?.last_ball_key)) {
      return;
    }
    if (
      item?.play?.related_balls[item?.play?.live?.last_ball_key]?.team_score
        ?.is_wicket
    ) {
      return (
        <Image
          src={
            window.location.origin +
            `/Images/${Data.scoreanimation.wicketanimation}`
          }
          className="ballanimation-score"
        />
      );
    }
    if (
      item?.play?.related_balls[
        item?.play?.live?.last_ball_key
      ]?.team_score?.runs?.toString() == "6"
    ) {
      return (
        <Image
          src={ window.location.origin +`/Images/${Data.scoreanimation.sixanimation}`}
          className="ballanimation-score"
        />
      );
    }
    if (
      item?.play?.related_balls[
        item?.play?.live?.last_ball_key
      ]?.team_score?.runs?.toString() == "4"
    ) {
      return (
        <Image
          src={window.location.origin +`/Images/${Data.scoreanimation.fouranimation}`}
          className="ballanimation-score"
        />
      );
    }
    return (
      <>
        {item?.play_status == "abandoned" && item?.play?.result?.msg}
        {item?.play_status == "lunch_break" && "Lunch Break"}
        {item?.play_status == "in_play" && item?.play?.related_balls && (
          <>
            <span
              dangerouslySetInnerHTML={rawMarkup(
                item?.play?.related_balls[item?.play?.live?.last_ball_key]
                  ?.comment
              )}
            />
            {/* <div>{item?.play?.live?.required_score?.title}</div> */}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <section className="Scorepage-section cmn-padding">
        <div className="container">
          <div className="scorepage-wrap">
            <div className="scorepage-header">
              <div className="score-header-grid">
                <div className="score-header-left">
                  <h4>{item?.teams[team1(item)?.split("_")[0]]?.code}</h4>
                  <div className="score-detail">
                    {item?.play?.innings_order?.length > 2 && (
                      <div>
                        {firebase_score_prev(
                          item,
                          inningOrder(item)?.filter(
                            (e) =>
                              e.split("_")[0] === team1(item)?.split("_")[0] &&
                              (e?.split("_")[1] < team1(item)?.split("_")[1] ||
                                e?.split("_")[1] === "superover")
                          )
                        )}
                      </div>
                    )}
                    {(!item?.matchData || item?.matchData === null) && (
                      <>
                        <div>{firebase_score_1(item)}</div>
                      </>
                    )}
                    {item?.matchData && item?.matchData !== null && (
                      <div>{socket_score_1(item)}</div>
                    )}
                    {(!item?.matchData || item?.matchData === null) &&
                      item?.status !== "not_started" && (
                        <span className="crr">
                          CRR{" "}
                          {item?.play?.innings[team1(item)]?.score?.run_rate}
                        </span>
                      )}
                    {item?.matchData && item?.matchData !== null && (
                      <span className="crr">
                        CRR{" "}
                        {
                          item?.matchData[
                            item?.matchData?.fb === "h" ? "hs" : "as"
                          ][0]?.rr
                        }
                        {
                          item?.matchData[
                            item?.matchData?.fb == "a" ? "as" : "hs"
                          ]?.rr
                        }
                      </span>
                    )}
                  </div>
                </div>
                <div className="score-header-center">
                  {/* <Image
                    src={
                      `/Images/${Data.scoreanimation.fouranimation}`
                    }
                    className="ballanimation-score"
                  /> */}
                  {/* <Image src={window.location.origin + `/Images/${Data.scoreanimation.sixanimation}`}  className="ballanimation-score"/> */}
                  {/* <Image
                    src={
                      `/Images/${Data.scoreanimation.wicketanimation}`
                    }
                    className="ballanimation-score"
                  /> */}
                  {item?.matchData && item?.matchData !== null && (
                    <h3>{socket_commentry(item)}</h3>
                  )}
                  {(!item?.matchData || item?.matchData === null) &&
                    item?.play_status === "in_play" && (
                      <h3>{firebase_commentry(item)}</h3>
                    )}
                </div>
                <div className="score-header-right">
                  <h4>{item?.teams[team2(item)?.split("_")[0]]?.code}</h4>
                  <div className="score-detail">
                    {item?.play?.innings_order?.length > 2 && (
                      <div>
                        {firebase_score_prev(
                          item,
                          inningOrder(item)?.filter(
                            (e) =>
                              e.split("_")[0] === team2(item)?.split("_")[0] &&
                              (e?.split("_")[1] < team2(item)?.split("_")[1] ||
                                e?.split("_")[1] === "superover")
                          )
                        )}
                      </div>
                    )}
                    {(!item?.matchData || item?.matchData == null) && (
                      <>
                        <div>{firebase_score_2(item)}</div>
                      </>
                    )}
                    {item?.matchData && item?.matchData !== null && (
                      <div>{socket_score_2(item)}</div>
                    )}
                    {(!item?.matchData || item?.matchData === null) &&
                      item?.status !== "not_started" && (
                        <span className="crr">
                          CRR{" "}
                          {item?.play?.innings[team2(item)]?.score?.run_rate}
                        </span>
                      )}
                    {item?.matchData && item?.matchData !== null && (
                      <span className="crr">
                        CRR{" "}
                        {
                          item?.matchData[
                            item?.matchData?.fb === "h" ? "as" : "hs"
                          ][0]?.rr
                        }
                        {
                          item?.matchData[
                            item?.matchData?.fb == "a" ? "hs" : "as"
                          ]?.rr
                        }
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={item?.status === "started" ? "live" : "not-live"}
                >
                  {play_status[item?.play_status]}
                </div>
              </div>
              <span className="result">
                {/* {i?.play?.live?.score?.msg_trail_by} */}
                {item?.matchData?.m?.length > 0
                  ? item?.matchData?.m
                  : item?.play?.live?.score?.msg_trail_by?.length > 0
                  ? item?.play?.live?.score?.msg_trail_by
                  : item?.play?.live?.score?.msg_lead_by?.length > 0
                  ? item?.play?.live?.score?.msg_lead_by
                  : item?.play?.live?.required_score?.title?.length > 0
                  ? item?.play?.live?.required_score?.title
                  : item?.status == "completed"
                  ? item?.play?.result?.msg
                  : item?.toss?.winner
                  ? `${
                      item?.teams[item?.toss?.winner]?.name
                    } won the toss and elected to ${item?.toss?.elected}`
                  : item?.play_status === "pre_match"
                  ? "match starting soon"
                  : ""}
              </span>
            </div>

            <div className="tab-wrap scorepage-tab">
              <div>
                {item?.matchData && item?.matchData !== null && (
                  <SocketRecentBalls item={item} />
                )}
                {(item.matchData === null || !item?.matchData) &&
                  item?.status === "started" && (
                    <FirebaseRecentBalls item={item} />
                  )}
              </div>
              <div className="tab-links center tranparent">
                <Button
                  className={matchInfoTab ? "active" : "disable"}
                  btnEvent={handlematchInfoClick}
                  btnText={"Match Info"}
                />
                {item?.status === "started" && (
                  <Button
                    className={liveTab ? "active" : "disable"}
                    btnEvent={handleliveTabClick}
                    btnText={"Commentry"}
                  />
                )}
                {item?.play?.innings && item?.play !== null && (
                  <Button
                    className={scoreCardTab ? "active" : "disable"}
                    btnEvent={handlescoreCardTabClick}
                    btnText={"ScoreCard"}
                  />
                )}
                {item?.squad && item?.squad["a"] && item?.squad["b"] && (
                  <Button
                    className={squadTab ? "active" : "disable"}
                    btnEvent={handleSquadTabClick}
                    btnText={"Squads"}
                  />
                )}
              </div>
              <div className="tab-content scorepage-tab-content">
                {scoreCardTab && <ScoreCard item={item} />}
              </div>
              <div className="tab-content matchinfo-tab-content">
                {matchInfoTab && <Overview item={item} />}
              </div>
              <div className="tab-content commentry-tab-content">
                {liveTab && <Commentry item={item} />}
              </div>
              <div className="tab-content scorepage-tab-content">
                {squadTab && <Squad item={item} />}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="result-tab-right">
          <ScoreCardSidebar />
        </div>
      </section> */}
    </>
  );
};

export default Score;
