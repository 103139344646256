import React from "react";
import Data from "../../Data/data.json";
import Image from "../Image/Image";
import './AppIcon.scss'

const AppIcon = () => {
  return (
    <>
      {/* <ul className="appicon-grid">
        {Data.AppIcon.map((item, index) => (
          <li key={index}>
            <Image
              src={window.location.origin + `/Images/${item.appIcon}`}
              alt={item.alt}
            />
          </li>
        ))}
      </ul> */}
      <ul className="appicon-grid">
          <li>
          <a href={Data.AppDownload.androidurl} target={'_blank'}><Image src={window.location.origin + `/Images/${Data.AppDownload.androidimg}`} /></a>  
          </li>
          <li>
          <a href={Data.AppDownload.appleurl} target={'_blank'}><Image src={window.location.origin + `/Images/${Data.AppDownload.appleimg}`} /></a>  
          </li>
      </ul>
    </>
  );
};

export default AppIcon;
