import React from 'react'
import ReactPlayer from 'react-player/youtube'
import '../Youtubebanner/Youtubebanner.scss'

const Youtubebanner = () => {
   
    return (
            <>
             <div className='youtubebannersection'>
                <div className='container'>
                      <ReactPlayer url='https://www.youtube.com/watch?v=Ig-7fST0_Oo' loop={true} muted   playing={true} width="100%" height="700px" />
                </div>
                </div>
            </>
    )
    
}

export default Youtubebanner;