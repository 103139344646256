import moment from "moment";
import Button from "../Button/Button";

const TournamentMatchItem = ({ item, key }) => {
  const scoreItem = (item) => {
    if (item?.status === "completed") {
      if (!item?.play && item?.play === null) {
        return;
      }
      return (
        <div key={key} className="tournaments-time">
          <>
            <span>
              <h4>{item?.teams["a"]?.code}</h4>
              <h5>
                {
                  item?.play?.innings[
                    item?.play?.innings_order?.filter(
                      (i) => i?.split("_")[0] === "a"
                    )[
                      item?.play?.innings_order?.filter(
                        (i) => i?.split("_")[0] === "a"
                      )?.length - 1
                    ]
                  ]?.score_str
                }
              </h5>
            </span>
            <span> vs </span>
            <span>
              <h4>{item?.teams["b"]?.code}</h4>
              <h5>
                {
                  item?.play?.innings[
                    item?.play?.innings_order?.filter(
                      (i) => i?.split("_")[0] === "b"
                    )[
                      item?.play?.innings_order?.filter(
                        (i) => i?.split("_")[0] === "b"
                      )?.length - 1
                    ]
                  ]?.score_str
                }
              </h5>
            </span>
          </>
        </div>
      );
    }
    return <div>Scheduled</div>;
  };
  //   if(item?.length<1){
  //       return<div>
  //           No Matches Available!
  //       </div>
  //   }
  return (
    <>
      <ul className="123">
        <li className="matchCover">
          <div className="tab-content">
            <div className="upcomingMatch">
              <div className="dateHeader">
                <h5>
                  {item?.short_name} | {item?.sub_title}
                </h5>
              </div>
              <div className="matchDetails">
                <h6>{moment.unix(new Date(item?.start_at))?.format("lll")}</h6>

                <div className="smatchTime">
                  <span>
                    {item?.venue?.name && item?.venue?.name !== null
                      ? `At ${item?.venue?.name}`
                      : ""}
                  </span>
                  <span className="lightText">
                    {item?.venue?.city &&
                    item?.venue?.city !== null &&
                    item?.venue?.country?.name &&
                    item?.venue?.country?.name !== null
                      ? `${item?.venue?.city} , ${item?.venue?.country?.name}`
                      : ""}
                  </span>
                </div>
                <div className="smatchVenue">
                  {/* <h5>{scoreItem(item)}</h5> */}
                  <h5 className="lightText">
                    {item?.status === "completed"
                      ? `${item?.play?.result?.msg}`
                      : ""}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};
export default TournamentMatchItem;
