export const firebaseConfig = {
  apiKey: "AIzaSyBFWGlVOP7FstMNJxRIeqZAvn-APZSozDg",
  authDomain: "rdream-news.firebaseapp.com",
  databaseURL: "https://rdream-news-default-rtdb.firebaseio.com",
  projectId: "rdream-news",
  storageBucket: "rdream-news.appspot.com",
  messagingSenderId: "1059178250275",
  appId: "1:1059178250275:web:90ae611eb4c73838c6b196",
  measurementId: "G-SW12WZ2NTY",
};

export const vapidKey =
  "BOvh2ng1rCPaZoBQZFMbZothRqHEzEi90NV-9T4CJAifQbeZ71YOXUg_dP4ImsyywE0tB-ZshbfZ5zKh17fGah8s";

// const database = initializeApp(firebaseConfig);
// export default database
