import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORIES_POST, NEWS_LOADING } from "../../Constant";
import apis from "../apis";
import loader from "../../Images/loader.gif";
import "./ScoreCardSidebar.scss";

const ScoreCardSidebar = () => {
  const dataRedux = useSelector((state) => state);
  console.log(dataRedux);
  const dispatch = useDispatch();

  // const [inning, setInning] = useState("a_1");
  // const [team, setTeam] = useState("a");
  const [news, setNews] = useState();
  const [ads, setAds] = useState();
  useEffect(() => {
    setNews(dataRedux?.CommonState?.CATEGORIES_POST);
  }, [dataRedux?.CommonState?.CATEGORIES_POST]);

  useEffect(() => {
    getPosts();
    getAds();
    return () => {};
  }, []);
  const getAds = () => {
    apis
      .getSidebarads()
      .then((res) => setAds(res[0]?.acf?.sidebar_ads))
      .catch((err) => console.log(err));
  };
  const getPosts = () => {
    apis
      .getPosts()
      .then((res) => {
        // console.log("blog response", res);
        // setItem(res);
        dispatch({
          type: CATEGORIES_POST,
          payload: res,
        });
        dispatch({
          type: NEWS_LOADING,
          payload: false,
        });
      })
      .catch((err) => console.log("err setting posts", err));
  };
  return (
    <div className="advertise-cover">
      {ads?.map((i, index) => (
        <div key={index} className="scorecard-addbox">
          <a href={i?.sidebar_ads_link} target="_blank">
            <img src={i?.sidebar_ads_image?.url} alt={loader} loading="lazy" />
          </a>
        </div>
      ))}
      {/* <div className="scorecard-addbox"></div> */}
      <div className="scorecard-featured-news">
        <h5>Featured News</h5>
        <ul>
          {news?.map((i, index) => {
            if (index > 2) {
              return;
            }
            return (
              <li key={index}>
                <a href={i?.link}>
                  <h6>
                    <p className="sidebar-inner-title">{i?.title?.rendered}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${
                          i?.content?.rendered?.length > 250
                            ? `${i?.content?.rendered?.slice(0, 150)}...`
                            : i?.content?.rendered
                        }`,
                      }}
                    ></div>
                  </h6>
                  <span>{new Date(i?.date).toDateString()}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>

    // <div className="tab-content">
    //   <div className="result-tab-content">

    //   </div>
    // </div>
  );
};
export default ScoreCardSidebar;
