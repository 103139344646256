import React, { Component, useEffect, useState } from "react";
import Data from "../../Data/data.json";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./Slider.scss";
import Image from "../Image/Image";
import apis from "../apis";

const Testimonials=()=> {
    
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            arrows: false,
          },
        },
      ],
    };

    const [item, setItem] = useState();
    // console.log("testimonials", item);
    useEffect(() => {
      apis
        .getTestimonials()
        .then((res) => {
          setItem(res[0]?.acf?.testimonials);
        })
        .catch((err) => console.log(err));
    }, []);
    return (
      <section className="slider-section">
        <div className="container">
          <Slider {...settings} className="slider-wrap center">
            {item?.map((item, index) => (
              <div key={index} className="slideBox">
                {/* <div className="slide-img">
                                        <Image src={window.location.origin + `/Images/${item.sliderImage}`} alt="Slider Image" />
                                    </div> */}
                <div className="slide-title">
                  {/* <h5>{item.sliderTitle}</h5> */}
                  <h4>Testimonials</h4>
                  {/* <h6>{item.silderHandle}</h6> */}
                </div>
                <p>{item?.testimonials_title}</p>
                <div className="testimonial-person">
                  <h5>"{item?.testimonials_description?.trim()}"</h5>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }

export default Testimonials
