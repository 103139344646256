import { useEffect, useState } from "react";
import {
  TwitterTweetEmbed,
  TwitterTimelineEmbed,
  TwitterVideoEmbed,
} from "react-twitter-embed";
import apis from "../../Components/apis";
import Layout from "../../Components/Layout/Layout";
import loader from "../../Images/loader.gif";
import '../Trending/Trending.scss';
const Trending = () => {
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    apis
      .getTrendingTweets()
      .then((res) => {
        setItem(res[0]?.acf?.trending);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);


  
  return (
    <Layout>
      {/* <ScoreBoard /> */}
      <div className="Trending-section cmn-padding">
          <div className="container">  
              <div className="sec-title">
                  <h2>Trending</h2>
              </div>
                     {isLoading && (
                        <section className="loaderimage">
                          <img src={loader} alt="Loading..." />
                        </section>
                      )}
              <div className="trending-cover">
                      {/* {isLoading && (
                        <section className="loaderimage">
                          <img src={loader} alt="Loading..." />
                        </section>
                      )} */}
                      {!isLoading &&
                        item?.map((i) => (
                          <TwitterTweetEmbed
                            // Here goes your copied ID.
                            tweetId={i?.trending_item}
                            // Style options goes here:
                            optins={{ width: "1280px" }}
                          />
                        ))}
              </div>
          </div>
      </div>
    </Layout>
  );
};
export default Trending;
