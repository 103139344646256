import React from 'react'
import Layout from '../../Components/Layout/Layout'

const Cricket = () => {
    return (
        <>
        <Layout>
            <h1>Cricket</h1>
        </Layout>
    </>
    )
}

export default Cricket
