import React from 'react'

const Button = (props) => {
  
  return (
    <>
        <button className={props.className} onClick={props.btnEvent} disabled={props.disabled?props.disabled:false}>{props.btnText}</button>
    </>
  )
}

export default Button