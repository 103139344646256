import React, { useState } from "react";
import { Link } from "react-router-dom";
import Data from "../../Data/data.json";
import Button from "../Button/Button";
import "../Squad/Squad.scss";
import Layout from "../Layout/Layout";
import ScoreCardSidebar from "../ScoreCardSidebar";

const Squad = ({ item }) => {
  const scoreCardData = Data.ScoreBoard[0].tabList[0].tabList[0];
  const [con1Tab, setcon1InfoTab] = useState(true);
  const [con2Tab, setcon2Tab] = useState(false);
  const [team, setTeam] = useState("a");

  const handlecon1Click = () => {
    setcon1InfoTab(true);
    setcon2Tab(false);
    setTeam("a");
  };
  const handlecon2Click = () => {
    setcon2Tab(true);
    setcon1InfoTab(false);
    setTeam("b");
  };

  return (
    <div className="tab-wrap scorecard-wrap squad-wrap">
      <div className="tab-links tranparent">
        <Button
          className={con1Tab ? "active" : "disable"}
          btnEvent={handlecon1Click}
          btnText={item?.teams["a"]?.code}
        />
        <Button
          className={con2Tab ? "active" : "disable"}
          btnEvent={handlecon2Click}
          btnText={item?.teams["b"]?.code}
        />
      </div>
      <div className="tab-content">
        <div className="squad-cover">
          <div className="squad-left">
            {true && (
              <div className="squad-bg">
                {/* <div className="overflow">
                            <table className="table2">
                              <thead className="custom-title">
                                <td colspan="4">{"ALL ROUNDER"}</td>
                              </thead>{" "}
                              {item?.squad[team]?.player_keys &&
                                item?.squad[team]?.player_keys
                                  ?.filter(
                                    (i) => item?.players[i]?.player?.roles[0] == "all_rounder"
                                  )
                                  .map((e, index) => (
                                    <tr>
                                      <td>{item?.players[e]?.player?.name}</td>
                                      <td>
                                        <span>
                                          {e == item?.squad[team].captain ? " (C)" : ""}
                                        </span>
                                        <span>
                                          {e == item?.squad[team].keeper ? "(WK)" : ""}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                            </table>
                            <table className="table2">
                              <thead className="custom-title">
                                <td colspan="4">{"BATSMAN"}</td>
                              </thead>
                              {item?.squad[team]?.player_keys &&
                                item?.squad[team]?.player_keys
                                  ?.filter(
                                    (i) =>
                                      item?.players[i]?.player?.roles[0] == "batsman" ||
                                      item?.players[i]?.player?.roles[0] == "keeper"
                                  )
                                  .map((e, index) => (
                                    <tr>
                                      <td>{item?.players[e]?.player?.name}</td>
                                      <td>
                                        <span>
                                          {e == item?.squad[team].captain ? " (C)" : ""}
                                        </span>
                                        <span>
                                          {e == item?.squad[team].keeper ? "(WK)" : ""}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                            </table>
                            <table className="table2">
                              <thead className="custom-title">
                                <td colspan="4">{"BOWLER"}</td>
                              </thead>
                              {item?.squad[team]?.player_keys &&
                                item?.squad[team]?.player_keys
                                  ?.filter(
                                    (i) => item?.players[i]?.player?.roles[0] == "bowler"
                                  )
                                  .map((e, index) => (
                                    <tr>
                                      <td>{item?.players[e]?.player?.name}</td>
                                      <td>
                                        <span>
                                          {e == item?.squad["a"].captain ? " (C)" : ""}
                                        </span>
                                        <span>
                                          {e == item?.squad["a"].keeper ? "(WK)" : ""}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                            </table>
                          </div> */}
                <div className="squad-details">
                  <div className="playing-squad">
                    <h5>Playing XI</h5>
                    <ul>
                      {item?.squad[team]?.playing_xi.map((e, index) => (
                        <li key={index}>
                          {/* <div></div> */}

                          {item?.players[e]?.player?.name}
                          <span>
                            {e == item?.squad[team].captain ? " (C)" : ""}
                          </span>
                          <span>
                            {e == item?.squad[team].keeper ? "(WK)" : ""}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="playing-bench">
                    <h5>Bench</h5>
                    <ul>
                      {item?.squad[team]?.player_keys
                        ?.filter(
                          (i) => item?.squad[team]?.playing_xi?.indexOf(i) < 0
                        )
                        ?.map((e, index) => (
                          <li key={index}>
                            {/* <div></div> */}

                            {item?.players[e]?.player?.name}
                            <span>
                              {e == item?.squad[team].captain ? " (C)" : ""}
                            </span>
                            <span>
                              {e == item?.squad[team].keeper ? "(WK)" : ""}
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="squad-right">
            <ScoreCardSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Squad;
