import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
import "./ScoreBoard.scss";
import Featured from "../Featured/Featured";
import Stumps from "../Stumps/Stumps";
import { useDispatch, useSelector } from "react-redux";
import { getDatabase, onValue, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import {
  FEATURED_MATCHES,
  firebaseConfig,
  FIREBASE_MATCHES,
  SOCKET_MATCHES,
} from "../../Constant";
import { socket1 } from "../apis/socket";
import apis from "../apis";
import { clientkeyadder } from "../apis/firebase";
import loader from "../../Images/loader.gif";

const ScoreBoard = () => {
  const dataRedux = useSelector((state) => state);
  const dispatch = useDispatch();
  const [item, setItem] = useState([]);
  console.log("item", item);

  const [featuredTab, setFeaturedTab] = useState(true);
  const [stumpsTab, setStumpsTab] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleMenClick = () => {
    setFeaturedTab(true);
    setStumpsTab(false);
  };
  const handleWomenClick = () => {
    setStumpsTab(true);
    setFeaturedTab(false);
  };
  useEffect(() => {
    if (dataRedux?.CommonState?.FEATURED_MATCHES?.length > 0) {
      setItem(dataRedux?.CommonState?.FEATURED_MATCHES);
    } else {
      setItem(dataRedux?.CommonState?.FIREBASE_MATCHES);
    }
  }, [dataRedux?.CommonState?.FEATURED_MATCHES]);
  useEffect(() => {
    setFeaturedMatches(
      dataRedux?.CommonState?.FIREBASE_MATCHES,
      dataRedux?.CommonState?.SOCKET_MATCHES
    );
  }, [
    dataRedux.CommonState.FIREBASE_MATCHES,
    dataRedux.CommonState.SOCKET_MATCHES,
  ]);
  const setFeaturedMatches = (firebase_matches, socket_matches) => {
    var matches = firebase_matches?.map((i) => {
      if (i?.client_api_key && i?.client_api_key === socket_matches?.e) {
        i["matchData"] = socket_matches;
      } else if (
        localStorage.getItem(`${i?.client_api_key}`) &&
        localStorage.getItem(`${i?.client_api_key}`) !== null
      ) {
        var data = JSON.parse(localStorage.getItem(`${i?.client_api_key}`));
        var time = new Date();
        if (data?.ttl >= time.getTime()) {
          i["matchData"] = JSON.parse(
            localStorage.getItem(`${i?.client_api_key}`)
          );
        } else {
          localStorage.removeItem(`${i?.client_api_key}`);
        }
      } else {
        i["matchData"] = null;
      }
      return i;
    });
    console.log("matches", matches);
    dispatch({
      type: FEATURED_MATCHES,
      payload: matches,
    });
  };
  useEffect(() => {
    setIsLoading(dataRedux?.CommonState?.LOADER?.MATCHES_LOADING);
  }, [dataRedux?.CommonState?.LOADER?.MATCHES_LOADING]);
  //   const app = initializeApp(firebaseConfig);
  //   const database = getDatabase(app);
  //   const dispatch = useDispatch();

  //   useEffect(() => {
  //     setData();
  //   }, []);
  //   const setData = () => {
  //     const starCountRef = ref(database, "rs-cricket/recent-matches");
  //     onValue(starCountRef, async (snapshot) => {
  //       if (snapshot.val() != null) {
  //         var data = await apis.getMatchesFromBackend();
  //         var matches = await clientkeyadder(snapshot.val(), data);
  //         dispatch({
  //           type: FIREBASE_MATCHES,
  //           payload: matches,
  //         });
  //       }
  //     });

  //     socket1.on("message", function (obj) {
  //       console.log("message", obj);
  //       let time = new Date();
  //       var ttl = time.getTime() + 1000 * 60 * 5;
  //       var data = {
  //         ...JSON.parse(obj),
  //         ttl: ttl,
  //       };
  //       localStorage.setItem(`${JSON.parse(obj).e}`, JSON.stringify(data));
  //       dispatch({
  //         type: SOCKET_MATCHES,
  //         payload: JSON.parse(obj),
  //       });
  //     });
  //   };
  if (isLoading) {
    return (
      <section className="loaderimage">
        <img src={loader} alt="Loading..." />
      </section>
    );
  }
  return (
    <>
      <section className="ScoreBoard-section cmn-padding">
        <div className="container">
          <div className="tab-wrap">
            <div className="tab-links left">
              <Button
                className={featuredTab ? "active" : "disable"}
                btnEvent={handleMenClick}
                btnText={Data.ScoreBoard[0].tabText}
              />
              <Button
                className={stumpsTab ? "active" : "disable"}
                btnEvent={handleWomenClick}
                btnText={Data.ScoreBoard[1].tabText}
              />
            </div>
            <div className="tab-content">
              {featuredTab && (
                <Featured
                  item={item?.filter((i) => i.status !== "completed" && i?.play_status!=="stumps")}
                />
              )}
              {stumpsTab && (
                <Stumps item={item?.filter((i) => i.status === "completed" || i?.play_status=="stumps")} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScoreBoard;
