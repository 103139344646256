import React from "react";
import { Link } from "react-router-dom";
import data from "../../Data/data.json";
import Image from "../Image/Image";
import './Social.scss'

const Social = () => {
  return (
    <>
      <ul className="socialList">
        {data.Social.map((item, index) => (
          <li key={index}>
          <a href={item.url} target="_blank">
            <Image
              src={window.location.origin + `/Images/${item.socialIcon}`}
              alt={item.alt}
            />
          </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Social;
