import React from 'react'
import './Appdownload.scss';
import Data from '../../Data/data.json';
import Image from "../Image/Image";


const Appdownload = () => {
return (
    <section className="app-section cmn-padding">
        <div className="container">
            <div className="appcover"> 
                <div className='app-left'>
                       <Image src={window.location.origin + `/Images/${Data.AppDownload.appdownloadimg}`} />
                </div>
                <div className='app-right'>
                     <p>{Data.AppDownload.appcontent}</p>
                     <h4>{Data.AppDownload.apptitle}</h4>
                     <div className='app-container'>
                          <a href={Data.AppDownload.androidurl} target={'_blank'}><Image src={window.location.origin + `/Images/${Data.AppDownload.androidimg}`} /></a>  
                          <a href={Data.AppDownload.appleurl} target={'_blank'}><Image src={window.location.origin + `/Images/${Data.AppDownload.appleimg}`} /></a>  
                     </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Appdownload