import "../SocketRecentBalls/RecentBalls.scss";

const FirebaseRecentBalls = ({ item }) => {
  return (
    <div className="recent-over-section">
      {item?.play?.live?.recent_overs && item?.play?.related_balls && (
        <div className="last-over">
          <span>Current Over : </span>
          <span>
            {" "}
            {item?.play?.live?.recent_overs[0]?.ball_keys?.map((i,index) => {
              if (!item?.play?.related_balls || !item?.play?.related_balls[i]) {
                return;
              }
              return (
                <p key={index}
                  className={
                    item?.play?.related_balls[i]?.team_score?.is_wicket
                      ? "wicket"
                      : item?.play?.related_balls[
                          i
                        ]?.team_score?.runs?.toString() === "4"
                      ? "four"
                      : item?.play?.related_balls[
                          i
                        ]?.team_score?.runs?.toString() == "6"
                      ? "six"
                      : ""
                  }
                >
                  {item?.play?.related_balls[i]?.team_score?.is_wicket
                    ? "W"
                    : item?.play?.related_balls[i]?.team_score?.runs?.toString()
                    ? item?.play?.related_balls[i]?.team_score?.runs?.toString()
                    : ""}
                </p>
              );
            })}
          </span>
        </div>
      )}
      {item?.play?.live?.recent_overs && item?.play?.related_balls && (
        <div className="last-over">
          <span>Last Over : </span>
          <span>
            {item?.play?.live?.recent_overs[1]?.ball_keys?.map((i,index) => {
              if (!item?.play?.related_balls || !item?.play?.related_balls[i]) {
                return;
              }
              return (
                <p key={index}
                  className={
                    item?.play?.related_balls[i]?.team_score?.is_wicket
                      ? "wicket"
                      : item?.play?.related_balls[
                          i
                        ]?.team_score?.runs?.toString() === "4"
                      ? "four"
                      : item?.play?.related_balls[
                          i
                        ]?.team_score?.runs?.toString() == "6"
                      ? "six"
                      : ""
                  }
                >
                  {item?.play?.related_balls[i]?.team_score?.is_wicket
                    ? "W"
                    : item?.play?.related_balls[i]?.team_score?.runs?.toString()
                    ? item?.play?.related_balls[i]?.team_score?.runs?.toString()
                    : ""}
                </p>
              );
            })}
          </span>
        </div>
      )}
    </div>
  );
};
export default FirebaseRecentBalls;
