import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
import loader from "../../Images/loader.gif";
import { Link } from "react-router-dom";
import "./AllRanking.scss";
import avatar from "../../Images/avatar.png";
import apis from "../apis/index";
const AllRanking = ({ item }) => {
  const [format, setFormat] = useState("TEST");
  const [category, setCategory] = useState("batsman");
  const [ads, setAds] = useState([]);
  // const[type,setType]=useState("")
  console.log(item);
  var categories = ["batsman", "team", "bowler", "allrounder"];
  var index = {
    team: ["Position", "Team", "Rating", "Points"],
    batsman: ["Position", "Hide Team", "Player", "Team", "Rating"],
    bowler: ["Position", "Hide Team", "Player", "Team", "Rating"],
    allrounder: ["Position", "Hide Team", "Player", "Team", "Rating"],
  };
  const [isLoading, setIsLoading] = useState(true);
  const dataRedux = useSelector((state) => state);

  // console.log(item);
  useEffect(() => {
    apis
      .getRankingSidebarads()
      .then((res) => setAds(res[0]?.acf?.ranking_sidebar))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setIsLoading(dataRedux?.CommonState?.LOADER?.RANKING_LOADING);
  }, [dataRedux?.CommonState?.LOADER?.RANKING_LOADING]);

  if (isLoading) {
    return (
      <section className="loaderimage">
        <img src={loader} alt="Loading..." />
      </section>
    );
  }

  return (
    <>
      <section className="Rank-section All-Rank cmn-padding">
        <div className="container">
          
          <div className="rank-wrapper">
          <div className="rank-before-img">
            <a href={ads[0]?.ranking_sidebar_link}>
              <img src={ads[0]?.ranking_sidebar_image?.url} />
            </a>
          </div>
            <div className="rank-left">
              <div className="sec-title">
                <h2>Ranking</h2>
              </div>
              <div className="tab-wrap">
                <div className="tab-content">
                  <div className="tab-links">
                    <div className="player-type">
                      {categories?.map((item) => (
                        <Button
                          className={category === item ? "active" : "disable"}
                          btnEvent={() => {
                            setCategory(item);
                          }}
                          btnText={item?.toUpperCase()}
                        />
                      ))}
                    </div>
                    <div className="match-type">
                      <Button
                        className={format === "TEST" ? "active" : "disable"}
                        btnEvent={() => {
                          setFormat("TEST");
                        }}
                        btnText={Data.iccMen[0].type}
                      />
                      <Button
                        className={format === "ODI" ? "active" : "disable"}
                        btnEvent={() => {
                          setFormat("ODI");
                        }}
                        btnText={Data.iccMen[1].type}
                      />
                      <Button
                        className={format === "T20" ? "active" : "disable"}
                        btnEvent={() => {
                          setFormat("T20");
                        }}
                        btnText={Data.iccMen[2].type}
                      />
                    </div>
                  </div>
                  <div className="tab-content">
                    {item[category]
                      ?.filter((i) => i?.matchformat === format)
                      ?.sort((x, y) => {
                        if (+x?.team_rank < +y?.team_rank) {
                          return -1;
                        } else {
                          return 1;
                        }
                      })
                      ?.map((item, index) => {
                        if (index === 0) {
                          return (
                            // <div className="top-rank-detail">

                            //   <div className="top-rank-title-content">
                            //     <span className="rank-title">1</span>
                            //     </div>
                            //     {category !== "team" && (
                            //       <div className="profile-img">
                            //         <img
                            //           src={item[`${category}_image`]?.url}
                            //           alt={avatar}
                            //         />
                            //       </div>
                            //     )}

                            //     <div className="top-player-content">
                            //     <span className="rank-title">Player</span>
                            //     <h4>{item[`${category}_name`]}</h4>
                            //     </div>
                            //     <div className="top-team-content">
                            //         {
                            //           <>
                            //             {category !== "team" && (
                            //               <>
                            //                 <span className="rank-title">Team</span>
                            //                 <h5>{item?.team_name}</h5>
                            //               </>
                            //             )}
                            //           </>
                            //         }
                            //     </div>
                            //     <div className="top-rating-content">
                            //         <span className="rank-title">Rating</span>
                            //         <h5>{item[`${category}_rating`]}</h5>
                            //     </div>
                            //     <div className="top-team-content">
                            //         {category == "team" && (
                            //           <>
                            //             <span className="rank-title">Points</span>
                            //             <h5>{item[`${category}_points`]}</h5>
                            //           </>
                            //         )}
                            //     </div>
                            // </div>
                            <div className="top-rank-detail">
                              <div className="top-table">
                                <div className="top-rank-left">
                                  <div className="profile-img">
                                    {category !== "team" && (
                                      <img
                                        src={item[`${category}_image`]?.url}
                                        alt={avatar}
                                      />
                                    )}
                                  </div>
                                  <div className="top-player-details">
                                    <div className="top-rank-player">
                                      <h5>{item[`${category}_name`]}</h5>
                                    </div>
                                    <div className="top-rank-playerteam">
                                      {
                                        <>
                                          {category !== "team" && (
                                            <>
                                              <h5>{item?.team_name}</h5>
                                            </>
                                          )}
                                        </>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className="top-rank-right">
                                  <div className="top-player-rating">
                                    <span className="rank-title">Rating</span>
                                    <h5>{item[`${category}_rating`]}</h5>
                                  </div>
                                  <div className="top-team-points">
                                    {category == "team" && (
                                      <>
                                        <span className="rank-title">
                                          Points
                                        </span>
                                        <h5>{item[`${category}_points`]}</h5>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    <table>
                      <tr className="theadclass">
                        {index[category]?.map((item, index) => (
                          <th key={index}>{item}</th>
                        ))}
                      </tr>

                      {item[category]
                        ?.filter((i) => i?.matchformat === format)
                        ?.sort((x, y) => {
                          if (+x?.team_rank < +y?.team_rank) {
                            return -1;
                          } else {
                            return 1;
                          }
                        })
                        ?.map((item, index) => {
                          if (index > 0) {
                            return (
                              <tr key={index}>
                                <td>{item[`${category}_rank`]}</td>
                                {category !== "team" && (
                                  <td>
                                    <img
                                      src={item[`${category}_image`]?.url}
                                      alt={avatar}
                                    />
                                  </td>
                                )}
                                {category !== "team" && (
                                  <td>{item[`${category}_name`]}</td>
                                )}
                                <td>{item?.team_name}</td>
                                <td>{item[`${category}_rating`]}</td>
                                {category == "team" && (
                                  <td>{item[`${category}_points`]}</td>
                                )}
                              </tr>
                            );
                          }
                        })}
                    </table>
                    {/* <a className="more-rank">
                      <h6>View list</h6>
                    </a> */}
                    {/* <Link to='/ranking'>See all</Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="rank-after-img">
            <a href={ads[1]?.ranking_sidebar_link}>
              <img src={ads[1]?.ranking_sidebar_image?.url} />
            </a>
          </div>
          </div>
          
        </div>
      </section>
    </>
  );
};
export default AllRanking;
