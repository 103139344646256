import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AllRanking from "../../Components/AllRanking";
import apis from "../../Components/apis";
import Layout from "../../Components/Layout/Layout";
import { RANKING, RANKING_LOADING } from "../../Constant";

const RankDetails = () => {
  const [item, setItem] = useState();
  const dataRedux=useSelector(state=>state)
  const dispatch=useDispatch()
  useEffect (()=>{
    setItem(dataRedux?.CommonState?.RANKING)
  },[dataRedux?.CommonState?.RANKING])
  useEffect(()=>{
    apis
    .getRanks()
    .then((res) => {
      // console.log(res[0]);
      // setItem(res[0]);
      dispatch({
          type : RANKING,
          payload : res[0]?.acf
      })
      dispatch({
          type : RANKING_LOADING,
          payload : false
      })
    })
    .catch((err) => console.log("err while fetching ranks", err));
  },[])
  return (
    <Layout>
      <section className="rankingdetail">
        <AllRanking item={item} />
      </section>
    </Layout>
  );
};
export default RankDetails;
