import React from "react";
import Data from "../../Data/data.json";
import { Link, NavLink } from "react-router-dom";
import "./Nav.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Nav = () => {
  const location=useLocation()
  
  return (
    <>
      <ul className="navList">
        {Data.Nav.map((item, index) => (
          <li key={index}>
            {(item.displayText === "News" ||
              item.displayText == "Contact Us") && (
              <a href={item.url}>{item.displayText}</a>
            )}
            {item.displayText !== "News" && item.displayText !== "Contact Us" && (
              <NavLink
                exact to={item.url}
                className={isActive=>isActive?"navtab":""
                }
              >
                {item.displayText}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Nav;
