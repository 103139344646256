import Layout from "../../Components/Layout/Layout";
import TournamentMatches from "../../Components/TournamentMatches";

const TournamentMatchesPage = () => {
  return (
    <Layout>
      <TournamentMatches />
    </Layout>
  );
};
export default TournamentMatchesPage;
