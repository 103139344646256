const img = {
  LOGO_IMG: window.location.origin + "/Images/logo.png",
  INSTAGRAM_IMG: window.location.origin + "/Images/instagram.png",
  TWITTER_IMG:window.location.origin +  "/Images/twitter.png",
  FACEBOOK_IMG: window.location.origin + "/Images/facebook.png",
  MENU_IMG: window.location.origin + "/Images/menu.png",
  CLOSE_IMG: window.location.origin + "/Images/close.png",
  ARROW_IMG: window.location.origin + "/Images/arrow.png",
  TWITTER_SLIDER_IMG: window.location.origin + "/Images/twitter-image.png",
  SLIDER_ARROW_IMG: window.location.origin + "/Images/slider-arrow.png",
};

export default img;
