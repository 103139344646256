import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
import "../Schedule/Scheduled.scss";
import "../Rank/Rank.scss";
import International from "../International/International";
import Domestic from "../Domestic/Domestic";
import { useDispatch, useSelector } from "react-redux";
import apis from "../apis";
import {
  ASSOCIATIONS,
  ASSOCIATION_LOADING,
  FEATURED_MATCHES,
  MATCH_DETAIL_ID,
  TOURNAMENTS,
  TOURNAMENTS_LOADING,
} from "../../Constant";
import Layout from "../Layout/Layout";
import AllFixtures from "../AllFixtures";
import loader from "../../Images/loader.gif";
import TournamentMatchItem from "../TournamentMatchItem";
import AllFixturesMatchItem from "../AllFixturesMatchItem";
import { Link } from "react-router-dom";
import ScoreCardSidebar from "../ScoreCardSidebar";

const Schedule = () => {
  const [allFixturesTab, setAllFixturesTab] = useState(false);
  const [liveTab, setLiveTab] = useState(true);
  const [resultsTab, setResultsTab] = useState(false);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dataRedux = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleAllFixturesTabClick = () => {
    setLiveTab(false);
    setResultsTab(false);
    setAllFixturesTab(true);
  };
  const handleResultstabClick = () => {
    setAllFixturesTab(false);
    setLiveTab(false);
    setResultsTab(true);
  };
  const handleLivetabClick = () => {
    setAllFixturesTab(false);
    setLiveTab(true);
    setResultsTab(false);
  };
  useEffect(() => {
    if (dataRedux?.CommonState?.FEATURED_MATCHES?.length > 0) {
      setItem(dataRedux?.CommonState?.FEATURED_MATCHES);
    } else {
      setItem(dataRedux?.CommonState?.FIREBASE_MATCHES);
    }
  }, [dataRedux?.CommonState?.FEATURED_MATCHES]);
  useEffect(() => {
    setFeaturedMatches(
      dataRedux?.CommonState?.FIREBASE_MATCHES,
      dataRedux?.CommonState?.SOCKET_MATCHES
    );
  }, [
    dataRedux.CommonState.FIREBASE_MATCHES,
    dataRedux.CommonState.SOCKET_MATCHES,
  ]);
  const setFeaturedMatches = (firebase_matches, socket_matches) => {
    var matches = firebase_matches?.map((i) => {
      if (i?.client_api_key && i?.client_api_key === socket_matches?.e) {
        i["matchData"] = socket_matches;
      } else if (
        localStorage.getItem(`${i?.client_api_key}`) &&
        localStorage.getItem(`${i?.client_api_key}`) !== null
      ) {
        var data = JSON.parse(localStorage.getItem(`${i?.client_api_key}`));
        var time = new Date();
        if (data?.ttl >= time.getTime()) {
          i["matchData"] = JSON.parse(
            localStorage.getItem(`${i?.client_api_key}`)
          );
        } else {
          localStorage.removeItem(`${i?.client_api_key}`);
        }
      } else {
        i["matchData"] = null;
      }
      return i;
    });
    console.log("matches", matches);
    dispatch({
      type: FEATURED_MATCHES,
      payload: matches,
    });
  };
  useEffect(() => {
    setIsLoading(dataRedux?.CommonState?.LOADER?.MATCHES_LOADING);
  }, [dataRedux?.CommonState?.LOADER?.MATCHES_LOADING]);
  console.log(item);

  return (
    <Layout>
      <section className="schedule-page cmn-padding">
        <div className="container">
          <div className="sec-title">
            <h2>FIXTURES</h2>
          </div>
          <div className="tab-wrap">
            <div className="tab-links tranparent">
              <Button
                className={liveTab ? "active" : "disable"}
                btnEvent={handleLivetabClick}
                btnText={"Featured"}
              />
              <Button
                className={resultsTab ? "active" : "disable"}
                btnEvent={handleResultstabClick}
                btnText={"Results"}
              />
              <Button
                className={allFixturesTab ? "active" : "disable"}
                btnEvent={handleAllFixturesTabClick}
                btnText={"All Fixtures"}
              />
            </div>
            <div className="tab-content">
              <div className="liveandresultfixture">
                <div className="fixture-left">
                
                  {isLoading && !allFixturesTab && (
                    <section className="loaderimage">
                      <img src={loader} alt="Loading..." />
                    </section>
                  )}
                  
                  <ul>
                    
                    {liveTab &&
                      !isLoading &&
                      <div className="fixture-bg">
                      {item
                        ?.filter((i) => i?.status !== "completed" && i?.play_status!=="stumps")
                        ?.map((i,index) => (
                          <Link
                            key={index}
                            onClick={() => {
                              dispatch({
                                type: MATCH_DETAIL_ID,
                                payload: i?.key,
                              });
                            }}
                            to={`/result/${`${i?.teams?.a?.code
                              ?.toLowerCase()
                              ?.trim()}_vs_${i?.teams?.b?.code
                              ?.toLowerCase()
                              ?.trim()}`}?_key=${Buffer?.from(i?.key).toString(
                              "base64"
                            )}`}
                          >
                            
                              <AllFixturesMatchItem item={i} loading={isLoading} />
                            
                          </Link>
                        )
                        )}
                        </div>
                        }
                    {resultsTab &&
                      !isLoading &&
                      <div className="fixture-bg">
                      {item
                        ?.filter((i) => i?.status == "completed" || i?.play_status==="stumps")
                        .map((i, index) => (
                          <Link
                            key={index}
                            onClick={() => {
                              dispatch({
                                type: MATCH_DETAIL_ID,
                                payload: i?.key,
                              });
                            }}
                            to={`/result/${`${i?.teams?.a?.code
                              ?.toLowerCase()
                              ?.trim()}_vs_${i?.teams?.b?.code
                              ?.toLowerCase()
                              ?.trim()}`}?_key=${Buffer?.from(i?.key).toString(
                              "base64"
                            )}`}
                          >
                              <AllFixturesMatchItem item={i} loading={isLoading} /> 
                          </Link>
                        ))}
                      </div>
                        }
                  </ul>
                
                  {allFixturesTab && <AllFixtures />}
                </div>
                <div className="fixture-right">
                    <ScoreCardSidebar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Schedule;
