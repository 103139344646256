import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Data from "../../Data/data.json";
import Button from "../Button/Button";
import ScoreCardSidebar from "../ScoreCardSidebar";

const Overview = ({ item }) => {
  const matchInfoData = Data.ScoreBoard[0].tabList[0].tabList[1];

  return (
    <div className="match-info-cover">
      <div className="match-info-left">
        <div className="matchinfo-tab">
          {(item?.toss?.winner || item?.play_status === "pre_match") && (
            <h4>
              {item?.toss?.winner
                ? `${
                    item?.teams[item?.toss?.winner]?.name
                  } won the toss and elected to ${item?.toss?.elected?.toUpperCase()}`
                : item?.play_status === "pre_match"
                ? "match starting soon"
                : ""}
            </h4>
          )}

          <div className="matchinfo-content">
            <div>
              <div className="matchinfo-raw">
                <h5>Match :</h5>
                <p>{item?.title}</p>
              </div>{" "}
              <div className="matchinfo-raw">
                <h5>Date :</h5>
                <p>{moment.unix(new Date(item?.start_at)).format("llll")}</p>
              </div>{" "}
            </div>

            {item?.toss?.winner && (
              <div>
                <div className="matchinfo-raw">
                  <h5>Toss :</h5>
                  <p>
                    {item?.toss?.winner
                      ? `${
                          item?.teams[item?.toss?.winner]?.name
                        } won the toss and elected to ${item?.toss?.elected?.toUpperCase()}`
                      : ""}
                  </p>
                </div>{" "}
              </div>
            )}
            <div className="">
              {item?.squad &&
                item?.squad["a"]?.playing_xi &&
                item?.squad["b"]?.playing_xi &&
                Object.keys(item?.teams)?.map((team, index) => {
                  return (
                    <div key={index} className="allplayer">
                      <h5>{item?.teams[team]?.name} Playing XI :</h5>
                      <div className="match-info-squad">
                        <div className="playing-team">
                          <h5>Playing</h5>
                          <ul>
                            {item?.squad[team]?.playing_xi.map((e, index) => (
                              <li key={index}>
                                {/* <div></div> */}

                                {item?.players[e]?.player?.name}
                                <span>
                                  {e == item?.squad[team].captain ? " (C)" : ""}
                                </span>
                                <span>
                                  {e == item?.squad[team].keeper ? "(WK)" : ""}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="match-info-squad">
                        {/* <h5>{item?.teams[team]?.name} Bench :</h5> */}
                        <div className="playing-team">
                          <h5>Bench</h5>
                          <ul>
                            {item?.squad[team]?.player_keys
                              ?.filter(
                                (i) =>
                                  item?.squad[team]?.playing_xi?.indexOf(i) < 0
                              )
                              ?.map((e, index) => (
                                <li key={index}>
                                  {/* <div></div> */}

                                  {item?.players[e]?.player?.name}
                                  <span>
                                    {e == item?.squad[team].captain
                                      ? " (C)"
                                      : ""}
                                  </span>
                                  <span>
                                    {e == item?.squad[team].keeper
                                      ? "(WK)"
                                      : ""}
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {/* <div>
                    <h5>{Data.ScoreBoard[0].tabList[0].country2} Squad :</h5>
                    <ul>
                      {matchInfoData.tabContent[0].squadList2.map((item, index) => (
                        <li>{item.name}</li>
                      ))}
                    </ul>
                  </div> */}
          </div>
          <div className="matchinfo-venue-bg">
            <div className="matchinfo-venue-content">
              {item?.venue?.name && (
                <div className="matchinfo-raw">
                  <h5>Venue :</h5>
                  <p>
                    {`${item?.venue?.name} ${
                      item?.venue?.city ? " ," + item?.venue?.city : ""
                    } ${
                      item?.venue?.country?.name
                        ? " ," + item?.venue?.country?.name
                        : ""
                    }`}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="match-info-right">
        <ScoreCardSidebar />
      </div>
    </div>
  );
};

export default Overview;
