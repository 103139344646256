import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Cricket from "./Pages/Cricket/Cricket";
import News from "./Pages/NewsTrash/News";
import Aboutus from "./Pages/About/About";
import LiveScore from "./Pages/LiveScore/LiveScore";
import Error from "./Pages/Error/Error";
import ScoreInner from "./Pages/ScoreInner/ScoreInner";
import "./Styles/globalstyle.scss";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  ASSOCIATIONS,
  ASSOCIATION_LOADING,
  firebaseConfig,
  FIREBASE_MATCHES,
  MATCHES_LOADING,
  SOCKET_MATCHES,
} from "./Constant";
import { useDispatch } from "react-redux";
import apis from "./Components/apis";
import { clientkeyadder } from "./Components/apis/firebase/index";
import { socket1 } from "./Components/apis/socket";
import Schedule from "./Components/Schedule/Schedule";
import TournamentMatches from "./Components/TournamentMatches";
import {
  ThemeContext,
  themes,
  widths,
} from "./Components/ThemeContext/themeContext";
import TournamentMatchesPage from "./Pages/TournamentMatchesPage";
import RankDetails from "./Pages/RankDetails";
import Drift from "react-driftjs";
import Button from "./Components/Button/Button";
import Videos from "./Pages/Videos";
import Trending from "./Pages/Trending";

function App() {
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const dispatch = useDispatch();

  const [darkMode, setDarkMode] = React.useState(true);
  const [fullScreen, setFullScreen] = React.useState(false);
  console.log(fullScreen);

  useEffect(() => {
    setData();
    optimizeLocalStorage();
    // annoynums();
    // console.log("in APP")
  }, []);
  const optimizeLocalStorage = () => {
    for (var i = 0; i < localStorage?.length; i++) {
      try {
        var data = JSON.parse(localStorage.getItem(`${localStorage.key(i)}`));
        var time = new Date();
        if (data?.ttl < time.getTime()) {
          localStorage.removeItem(`${localStorage.key(i)}`);
        }
      } catch (err) {}
    }
  };
  const setData = () => {
    apis
      .getAssociations()
      .then((res) => {
        dispatch({
          type: ASSOCIATIONS,
          payload: res?.associations,
        });
        dispatch({
          type: ASSOCIATION_LOADING,
          payload: false,
        });
      })
      .catch((err) => console.log(err));
    const starCountRef = ref(database, "rs-cricket/recent-matches");

    onValue(starCountRef, async (snapshot) => {
      if (snapshot.val() != null) {
        var data = await apis.getMatchesFromBackend();
        var matches = await clientkeyadder(snapshot.val(), data);
        dispatch({
          type: FIREBASE_MATCHES,
          payload: matches,
        });
        dispatch({
          type: MATCHES_LOADING,
          payload: false,
        });
      }
      if (snapshot.val() == null) {
        var data = await apis.getMatchesFromBackend();
        dispatch({
          type: FIREBASE_MATCHES,
          payload: data,
        });
        dispatch({
          type: MATCHES_LOADING,
          payload: false,
        });
      }
    });

    socket1.on(
      "message",
      function (obj) {
        console.log("message", obj);
        let time = new Date();
        var ttl = time.getTime() + 1000 * 60 * 5;
        var data = {
          ...JSON.parse(obj),
          ttl: ttl,
        };
        localStorage.setItem(`${JSON.parse(obj).e}`, JSON.stringify(data));
        dispatch({
          type: SOCKET_MATCHES,
          payload: JSON.parse(obj),
        });
      },
      async (error) => {
        console.log("err connecting socket", error);
        const data = await apis.getMatchesFromBackend();
        dispatch({
          type: FIREBASE_MATCHES,
          payload: data,
        });
        dispatch({
          type: MATCHES_LOADING,
          payload: false,
        });
      }
    );
  };

  return (
    <>
      <ThemeContext.Consumer>
        {({ changeTheme, changeWidth }) => (
          <>
            <div className="themedarklightbox">
              {/* <a
                color="link"
                onClick={() => {
                  setDarkMode(!darkMode);
                  changeTheme(darkMode ? themes.light : themes.dark);
                }}
                className="darklightbutton"
              >
                <i className={darkMode ? 'fas fa-sun' : 'fas fa-moon'}></i>
                <span className="d-lg-none d-md-block">Switch mode</span>
              </a> */}
              <input
                className="container_toggle"
                type="checkbox"
                id="switch"
                name="mode"
              />
              <label
                htmlFor="switch"
                onClick={() => {
                  setDarkMode(!darkMode);
                  changeTheme(darkMode ? themes.light : themes.dark);
                }}
              >
                Toggle
              </label>
            </div>
            <div className="widthlargesmall">
              <button
                onClick={() => {
                  setFullScreen(!fullScreen);
                  changeWidth(fullScreen ? widths.full : widths.normal);
                }}
              ></button>
            </div>
          </>
        )}
      </ThemeContext.Consumer>

      <Drift
        appId="igxpycuvpzgs"
        style={{
          bottom: "100px",
        }}
      />

      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/cricket" component={Cricket} />
        <Route path="/news" component={News} />
        <Route path="/aboutus" component={Aboutus} />
        <Route path="/livescore" component={LiveScore} />
        <Route path="/result/:id" component={ScoreInner} />
        <Route path="/tournaments" component={Schedule} exact />
        <Route
          path="/tournaments/:association_name/fixtures/:tournament_name"
          component={TournamentMatchesPage}
          exact
        />
        <Route path="/ranking" component={RankDetails} />
        <Route path="/videos" component={Videos} />
        <Route path="/trending" component={Trending} />
        <Route component={Error} />
      </Switch>
    </>
  );
}

export default App;
