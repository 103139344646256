import {io} from'socket.io-client'
export const socket1 = io(
    "https://socket.scoreshub.live/?token=SCH674626865453F531E9B8FAF465",
    {
      transports: ["websocket"],
    }
  );

  console.log("socket1", socket1);

  socket1.on("connect", function () {
    console.log("connected");
    // isConnected = true;
  });