import React, { useState, useEffect } from "react";
import { ThemeContext, themes, widths } from "./themeContext.js";

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(themes.dark);
  const [width, setWidth] = useState(widths.normal);

  function changeTheme(theme) {
    setTheme(theme);
  }
  function changeWidth(width) {
    setWidth(width);
  }

  useEffect(() => {
    switch (theme) {
      case themes.light:
        document.body.classList.add("light-theme");
        break;
      case themes.dark:
      default:
        document.body.classList.remove("light-theme");
        break;
    }
    
  }, [theme]);
  useEffect(() => {
    switch (width) {
      case widths.full:
        document.body.classList.add("full-screen-mode");
        break;
      case widths.normal:
      default:
        document.body.classList.remove("full-screen-mode");
        break;
    }
  }, [width]);

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        changeTheme: changeTheme,
        width: width,
        changeWidth: changeWidth,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
