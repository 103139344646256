import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
import "../Schedule/Scheduled.scss";
import "../Rank/Rank.scss";
import International from "../International/International";
import Domestic from "../Domestic/Domestic";
import { useDispatch, useSelector } from "react-redux";
import apis from "../apis";
import {
  ASSOCIATIONS,
  ASSOCIATION_LOADING,
  TOURNAMENTS,
  TOURNAMENTS_LOADING,
} from "../../Constant";
import Layout from "../Layout/Layout";

const AllFixtures = () => {
  const [internationalTab, setInternationalTab] = useState(true);
  const [domesticTab, setDomesticTab] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTournamentLoading, setIsTournamentLoading] = useState(true);
  const dataRedux = useSelector((state) => state);

  const handleInternationalClick = () => {
    setInternationalTab(true);
    setDomesticTab(false);
  };
  const handleDomesticClick = () => {
    setInternationalTab(false);
    setDomesticTab(true);
  };
  useEffect(() => {
    setIsLoading(dataRedux?.CommonState?.LOADER?.ASSOCIATION_LOADING);
    setIsTournamentLoading(dataRedux?.CommonState?.LOADER?.TOURNAMENTS_LOADING);
  }, [
    dataRedux?.CommonState?.LOADER?.ASSOCIATION_LOADING,
    dataRedux?.CommonState?.LOADER?.TOURNAMENTS_LOADING,
  ]);
  return (
    <div className="tab-wrap">
      <div className="tab-links tranparent">
        <Button
          className={internationalTab ? "active" : "disable"}
          btnEvent={handleInternationalClick}
          btnText={"International"}
          disabled={isLoading || isTournamentLoading}
        />
        <Button
          className={domesticTab ? "active" : "disable"}
          btnEvent={handleDomesticClick}
          btnText={"Domestic"}
          disabled={isLoading || isTournamentLoading}
        />
      </div>
      <div className="tab-content">
        <div className="scheduled-cover">
          {internationalTab && <International />}
          {domesticTab && <Domestic />}
        </div>
      </div>
    </div>
  );
};

export default AllFixtures;
