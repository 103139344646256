import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Data from "../../Data/data.json";
import "../Rank/Rank.scss";
import { useDispatch, useSelector } from "react-redux";
import apis from "../apis";
import {
  ASSOCIATIONS,
  ASSOCIATION_LOADING,
  month_string,
  TOURNAMENTS,
  TOURNAMENTS_LOADING,
} from "../../Constant";
import moment from "moment";
import loader from "../../Images/loader.gif";
import { Link } from "react-router-dom";
const International = () => {
  const [format, setFormat] = useState("ODI");
  const [associations, setAssociations] = useState([]);
  const [cur_association, setcur_association] = useState(
    "c__board__icc__c2ab7ee61"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isTournamentLoading, setIsTournamentLoading] = useState(true);
  // console.log("cur_association", cur_association);
  const priority = [
    "ECB",
    "PCB",
    "BCB",
    "NZC",
    "CSA",
    "CWI",
    "CA",
    "BCCI",
    "ACC",
    "ICC",
  ];

  const dispatch = useDispatch();
  const dataRedux = useSelector((state) => state);
  console.log(dataRedux);
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    setIsLoading(dataRedux?.CommonState?.LOADER?.ASSOCIATION_LOADING);
    setIsTournamentLoading(dataRedux?.CommonState?.LOADER?.TOURNAMENTS_LOADING);
  }, [
    dataRedux?.CommonState?.LOADER?.ASSOCIATION_LOADING,
    dataRedux?.CommonState?.LOADER?.TOURNAMENTS_LOADING,
  ]);
  useEffect(() => {
    // console.log("in Tournaments")
    apis
      .getAssociations()
      .then((res) => {
        dispatch({
          type: ASSOCIATIONS,
          payload: res?.associations,
        });
        dispatch({
          type: ASSOCIATION_LOADING,
          payload: false,
        });
      })
      .catch((err) => console.log(err));
    fetchTournaments(cur_association);
  }, []);
  useEffect(() => {
    setAssociations(dataRedux?.CommonState?.ASSOCIATIONS);
  }, [dataRedux?.CommonState?.ASSOCIATIONS]);
  useEffect(() => {
    setTournaments(dataRedux?.CommonState?.TOURNAMENTS);
  }, [dataRedux?.CommonState?.TOURNAMENTS]);
  const fetchTournaments = (cur_association) => {
    if (
      dataRedux?.CommonState?.TOURNAMENTS?.length > 0 &&
      dataRedux?.CommonState?.TOURNAMENTS[0]?.association_key ===
        cur_association
    ) {
      return;
    }
    dispatch({
      type: TOURNAMENTS_LOADING,
      payload: true,
    });

    apis
      .getAssociationsFeaturedTournaments(cur_association)
      .then((res) => {
        dispatch({
          type: TOURNAMENTS,
          payload: res?.tournaments,
        });
        dispatch({
          type: TOURNAMENTS_LOADING,
          payload: false,
        });
      })
      .catch((err) => console.log(err));
  };
  var getMonthandYear = (item) => {
    // console.log(item);
    var date = moment.unix(new Date(item)).format("ll");
    var m = month_string[moment(date, "ll")?.month()];
    var y = moment(date, "ll")?.year();
    // console.log("date", m + " " + y);

    // var m = date;

    return m + " " + y;
  };
  // console.log("tournaments", tournaments);
  var inter_items = tournaments?.map((i) => {
    // console.log(i?.start_date)
    i["group_date"] = getMonthandYear(+i?.start_date);
    return i;
  });
  // console.log("inter_items", inter_items);
  var curDate = new Date();
  const groupBy = (array, key) => {
    // Return the reduced array
    return array?.reduce((result, currentItem) => {
      // If an array already present for key, push it to the array. Otherwise create an array and push the object.
      (result[currentItem[key]] = result[currentItem[key]] || []).push(
        currentItem
      );
      // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
      return result;
    }, {}); // Empty object is the initial value for result object
  };
  // if (inter_items?.length < 1) {
  //   return <div>No Tournaments Available!</div>;
  // }

  function monthDiff(year, month) {
    var dateOne = new Date(year?.toString(), month?.toString(), "01"); //Year, Month, Date
    var dateTwo = new Date(
      new Date().getFullYear(),
      moment().format("MM") - 1,
      "01"
    ); //Year, Month, Date
    // console.log(dateOne,dateTwo)
    if (dateOne?.getTime() >= dateTwo?.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  // console.log(moment.unix(1647043200).format("M"))
  return (
    <>
      {isLoading && (
        <section className="loaderimage">
          <img src={loader} alt="Loading..." />
        </section>
      )}
      {!isLoading && (
        <div className="tab-links">
          {associations
            ?.filter(
              (i) =>
                (!i?.country?.code || i?.country?.code === null) &&
                i?.code !== "VG"
            )
            .sort((x, y) => {
              if (
                // priority?.indexOf(x?.code) > -1 &&
                priority?.indexOf(x?.code) < priority?.indexOf(y?.code)
              ) {
                return 1;
              } else {
                return -1;
              }
            })
            .map((item) => (
              <Button
                className={cur_association === item?.key ? "active" : "disable"}
                btnEvent={() => {
                  setcur_association(item?.key);
                  // setIsLoading(true);
                  fetchTournaments(item?.key);
                }}
                btnText={item?.country?.name ? item?.country?.name : item?.code}
                disabled={isLoading || isTournamentLoading}
              />
            ))}
        </div>
      )}
      {!isTournamentLoading && inter_items?.length < 1 && (
        <div className="notavailable-text">No Tournaments Available!</div>
      )}
      {/* <div className="tab-links">
        <Button
          className={format === "ODI" ? "active" : "disable"}
          btnEvent={() => {
            setFormat("ODI");
          }}
          btnText={Data.iccMen[1].type}
        />
        <Button
          className={format === "T20" ? "active" : "disable"}
          btnEvent={() => {
            setFormat("T20");
          }}
          btnText={Data.iccMen[2].type}
        />
        <Button
          className={format === "TEST" ? "active" : "disable"}
          btnEvent={() => {
            setFormat("TEST");
          }}
          btnText={Data.iccMen[0].type}
        />
      </div> */}
      {!isLoading && isTournamentLoading && (
        <section className="loaderimage">
          <img src={loader} alt="Loading..." />
        </section>
      )}
      {!isTournamentLoading && (
        <div className="tab-content">
          {!(!isTournamentLoading && inter_items?.length < 1) && (
            <div className="schedule-header">
              <div className="schedule-header-month">
                <h5>Month</h5>
              </div>
              <div className="schedule-header-details">
                <h5>Series Name</h5>
              </div>
            </div>
          )}
          {inter_items?.filter((i) =>
            monthDiff(
              moment.unix(i?.last_scheduled_match_date).format("YYYY"),
              moment.unix(i?.last_scheduled_match_date).format("M") - 1
            )
          ) &&
            Object.keys(
              groupBy(
                inter_items?.filter((i) =>
                  monthDiff(
                    moment.unix(i?.last_scheduled_match_date).format("YYYY"),
                    moment.unix(i?.last_scheduled_match_date).format("M") - 1
                  )
                ),
                "group_date"
              )
            )
              ?.sort((x, y) => {
                if (+x?.split(" ")[1] > +y?.split(" ")[1]) {
                  return -1;
                } else if (+x?.split(" ")[1] == +y?.split(" ")[1]) {
                  if (
                    month_string?.indexOf(x?.split(" ")[0]) >
                    month_string?.indexOf(y?.split(" ")[0])
                  ) {
                    return -1;
                  } else {
                    return 1;
                  }
                } else {
                  return +1;
                }
              })
              ?.map((item, index) => {
                return (
                  <div key={index} className="schedule-raw">
                    <div className="schedule-month">
                      <div className="upcomingMatch">{item}</div>
                    </div>
                    <div className="schedule-details">
                      <>
                        {inter_items
                          ?.filter((i) => i?.group_date === item)
                          ?.filter((i) =>
                            monthDiff(
                              moment
                                .unix(i?.last_scheduled_match_date)
                                .format("YYYY"),
                              moment
                                .unix(i?.last_scheduled_match_date)
                                .format("M") - 1
                            )
                          )
                          ?.sort((x, y) => {
                            if (x?.start_date && !y?.start_date) {
                              return 1;
                            } else if (!x?.start_date && y?.start_date) {
                              return -1;
                            } else if (
                              Math.abs(x?.start_date - curDate?.getTime()) <
                              Math.abs(y?.start_date - curDate?.getTime())
                            ) {
                              return 1;
                            } else {
                              return -1;
                            }
                          })
                          ?.reverse()
                          ?.map((item, index) => {
                            return (
                              <Link
                                key={index}
                                to={`/tournaments/${
                                  associations?.filter(
                                    (i) => i.key === item?.association_key
                                  )[0]?.code
                                }/fixtures/${item?.short_name
                                  ?.split(" ")
                                  .join("-")}`}
                              >
                                <div className="upcomingMatch">
                                  <ul>
                                    <li className="matchCover">
                                      <div className="dateHeader">
                                        {/* <h5>
                                          {moment
                                            .unix(new Date(+item?.start_date))
                                            .format("ll")}
                                        </h5> */}
                                      </div>
                                      <div className="matchDetails">
                                        <span>
                                          <h6>{item?.name}</h6>{" "}
                                        </span>
                                        {/* <div className="smatchVenue">
                                          <span>India vs Srilanka, 1st TEST</span>
                                          <span className="lightText">
                                            Zahur Ahmed Chowdhury Stadium,
                                          </span>
                                        </div> */}
                                        <div className="smatchTime">
                                          <span>
                                            {moment
                                              .unix(new Date(+item?.start_date))
                                              .format("ll") +
                                              " - " +
                                              moment
                                                .unix(
                                                  item?.last_scheduled_match_date
                                                )
                                                .format("ll")}
                                          </span>
                                          {/* <span className="lightText">
                                            10:00 PM GMT (Feb 24) / 11:00 AM LOCAL
                                          </span> */}
                                        </div>
                                      </div>
                                    </li>
                                    {/* <li className="matchCover">
                                      <div className="dateHeader">
                                        <h5>Feb 26, 2022</h5>
                                      </div>
                                      <div className="matchDetails">
                                        <span>
                                          <h6>Afghanistan tour of Bangladesh, 2022</h6>
                                        </span>
                                        <div className="smatchVenue">
                                          <span>
                                            Bangladesh vs Afghanistan, 2nd TEST
                                          </span>
                                          <span className="lightText">
                                            Zahur Ahmed Chowdhury Stadium,
                                          </span>
                                        </div>
                                        <div className="smatchTime">
                                          <span>3:30 AM (Feb 25)</span>
                                          <span className="lightText">
                                            10:00 PM GMT (Feb 24) / 11:00 AM LOCAL
                                          </span>
                                        </div>
                                      </div>
                                    </li> */}
                                  </ul>
                                </div>
                              </Link>
                            );
                          })}
                      </>
                    </div>
                  </div>
                );
              })}
        </div>
      )}
    </>
  );
};

export default International;
