export const base_array = (item) => {
  return item?.play?.innings != undefined ? item?.play?.innings_order : [];
};

export const array_1 = (item) => {
  return item?.play?.innings != undefined
    ? Object.keys(item?.play?.innings)
    : [];
};

export const inningOrder = (item) => {
  return [...new Set([...base_array(item), ...array_1(item)])];
};

export const team1 = (item) => {
  return inningOrder(item).length >= 2
    ? inningOrder(item)[inningOrder(item).length - 2]
    : "a_1";
};
export const team2 = (item) => {
  return inningOrder(item).length >= 1
    ? inningOrder(item)[inningOrder(item).length - 1]
    : "b_1";
};
export const firebase_score_1 = (item) => {
  // console.log(inningOrder(item));
  // console.log(item);
  return (
    <>
      <>
        {item?.status == "started" && item?.play?.live?.innings == team1(item)
          ? item?.play?.live?.score?.runs +
            "/" +
            item?.play?.live?.score?.wickets
          : item?.play_status == "result"
          ? item?.play?.innings[team1(item)]?.score?.runs &&
            item?.play?.innings[team1(item)]?.wickets >= 0
            ? item?.play?.innings[team1(item)]?.score?.runs +
              "/" +
              item?.play?.innings[team1(item)]?.wickets
            : "-"
          : item?.status == "completed"
          ? ""
          : item?.play_status == "in_play" &&
            item?.play?.innings[team1(item)]?.is_completed
          ? item?.play?.innings[team1(item)]?.score?.runs +
            "/" +
            item?.play?.innings[team1(item)]?.wickets
          : item?.play_status == "stumps" ||
            item?.play_status == "lunch_break" ||
            item?.play_status == "dinner_break"
          ? item?.play?.innings[team1(item)]?.score?.runs &&
            item?.play?.innings[team1(item)]?.wickets
            ? item?.play?.innings[team1(item)]?.score?.runs +
              "/" +
              item?.play?.innings[team1(item)]?.wickets
            : "-"
          : ""}
      </>
      <>
        {item?.status !== "not_started" && (
          <>
            {item?.status == "started" &&
            item?.play?.live?.innings == team1(item)
              ? ` (${item?.play?.live?.score?.overs?.join(".") + ""})`
              : ` ${
                  item?.play_status == "result"
                    ? +item?.play?.innings[team1(item)]?.overs?.join(".") > 0
                      ? "(" +
                        item?.play?.innings[team1(item)]?.overs?.join(".") +
                        ") "
                      : ""
                    : item?.status == "completed"
                    ? "-"
                    : item?.play_status == "in_play" &&
                      item?.play?.innings[team1(item)]?.is_completed
                    ? "(" +
                      item?.play?.innings[team1(item)]?.overs?.join(".") +
                      ") "
                    : item?.play_status == "stumps" ||
                      item?.play_status == "lunch_break" ||
                      item?.play_status == "dinner_break"
                    ? item?.play?.innings[team1(item)]?.overs
                      ? "(" +
                        item?.play?.innings[team1(item)]?.overs?.join(".") +
                        ") "
                      : ""
                    : ""
                }`}
          </>
        )}
      </>
    </>
  );
};
export const firebase_score_prev = (item, inning) => {
  // console.log(inningOrder(item));
  console.log("inning", inning);
  return (
    <>
      <>
        {item?.status == "started" && item?.play?.live?.innings == inning
          ? item?.play?.live?.score?.runs +
            "/" +
            item?.play?.live?.score?.wickets
          : item?.play_status == "result"
          ? item?.play?.innings[inning]?.score?.runs &&
            item?.play?.innings[inning]?.wickets
            ? item?.play?.innings[inning]?.score?.runs +
              "/" +
              item?.play?.innings[inning]?.wickets
            : "-"
          : item?.status == "completed"
          ? ""
          : item?.play_status == "in_play" &&
            item?.play?.innings[inning]?.is_completed
          ? item?.play?.innings[inning]?.score?.runs +
            "/" +
            item?.play?.innings[inning]?.wickets
          : item?.play_status == "stumps" ||
            item?.play_status == "lunch_break" ||
            item?.play_status == "dinner_break"
          ? item?.play?.innings[inning]?.score?.runs &&
            item?.play?.innings[inning]?.wickets
            ? item?.play?.innings[inning]?.score?.runs +
              "/" +
              item?.play?.innings[inning]?.wickets
            : "-"
          : ""}
        {" & "}
      </>
      {/* <>
          {item?.status !== "not_started" && (
            <>
              {item?.status == "started" && item?.play?.live?.innings == inning
                ? ` (${item?.play?.live?.score?.overs.join(".") + ""})`
                : ` (${
                    item?.play_status == "result"
                      ? item?.play?.innings[inning]?.overs
                        ? item?.play?.innings[inning]?.overs.join(".") + ""
                        : ""
                      : item?.status == "completed"
                      ? "-"
                      : item?.play_status == "in_play" &&
                        item?.play?.innings[inning]?.is_completed
                      ? item?.play?.innings[inning]?.overs.join(".") + ""
                      : item?.play_status == "stumps" ||
                        item?.play_status == "lunch_break" ||
                        item?.play_status == "dinner_break"
                      ? item?.play?.innings[inning]?.overs
                        ? item?.play?.innings[inning]?.overs.join(".") + ""
                        : ""
                      : ""
                  })`}
            </>
          )}
        </> */}
    </>
  );
};
export const firebase_score_2 = (item) => {
  console.log(inningOrder(item));
  console.log(item);
  return (
    <>
      <>
        {item?.status == "started" && item?.play?.live?.innings == team2(item)
          ? item?.play?.live?.score?.runs +
            "/" +
            item?.play?.live?.score?.wickets
          : item?.play_status == "result"
          ? item?.play?.innings[team2(item)]?.score?.runs &&
            item?.play?.innings[team2(item)]?.wickets >= 0
            ? item?.play?.innings[team2(item)]?.score?.runs +
              "/" +
              item?.play?.innings[team2(item)]?.wickets
            : "-"
          : item?.status == "completed"
          ? "-"
          : item?.play_status == "in_play" &&
            item?.play?.innings[team2(item)]?.is_completed
          ? item?.play?.innings[team2(item)]?.score?.runs +
            "/" +
            item?.play?.innings[team2(item)]?.wickets
          : item?.play_status == "in_play" ||
            item?.play_status === "innings_break"
          ? "Yet to bat"
          : item?.play_status == "stumps"
          ? item?.play?.innings[team2(item)]?.score?.runs +
            "/" +
            item?.play?.innings[team2(item)]?.wickets
          : ""}
      </>
      <>
        {item?.status !== "not_started" && (
          <>
            {item?.status == "started" &&
            item?.play?.live?.innings == team2(item)
              ? ` (${item?.play?.live?.score?.overs?.join(".") + ""})`
              : item?.play_status == "result"
              ? +item?.play?.innings[team2(item)]?.overs?.join(".") > 0
                ? `(${item?.play?.innings[team2(item)]?.overs?.join(".") + ""})`
                : ""
              : item?.play?.innings[team2(item)] == "completed"
              ? "-"
              : item?.play_status == "in_play" &&
                item?.play?.innings[team2(item)]?.is_completed
              ? `(${item?.play?.innings[team2(item)]?.overs?.join(".") + ""})`
              : item?.play_status == "stumps" ||
                item?.play_status == "lunch_break" ||
                item?.play_status == "dinner_break"
              ? item?.play?.innings[team2(item)]?.overs
                ? `(${item?.play?.innings[team2(item)]?.overs?.join(".") + ""})`
                : ""
              : ""}
          </>
        )}
      </>
    </>
  );
};
export const socket_score_1 = (item) => {
  return (
    <>
      <>
        {item?.matchData[item?.matchData?.fb === "h" ? "hs" : "as"][0]?.r +
          "/" +
          item?.matchData[item?.matchData?.fb === "h" ? "hs" : "as"][0]?.w}
      </>
      <>
        {item?.matchData[item?.matchData?.fb === "h" ? "hs" : "as"][0]?.o} Overs
      </>
    </>
  );
};
export const socket_score_2 = (item) => {
  return (
    <>
      <>
        {item?.matchData[item?.matchData?.fb === "h" ? "as" : "hs"][0]?.r +
          "/" +
          item?.matchData[item?.matchData?.fb === "h" ? "as" : "hs"][0]?.w}
      </>
      <>
        {item?.matchData[item?.matchData?.fb === "h" ? "as" : "hs"][0]?.o} Overs
      </>
    </>
  );
};
