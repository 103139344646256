import React from 'react'
import './Youtubesection.scss';
import Image from "../Image/Image";
import Data from "../../Data/data.json"
import YoutubeEmbed from "../../Components/YoutubeEmbed/YoutubeEmbed"


const Youtubesection = () => {
return (
    <section className="Youtube-section cmn-padding">
        <div className="container">
        <div className="youtubecover"> 
             <div className='youtube-header'>
                   <Image src={window.location.origin + `/Images/${Data.Youtubesection.yuoutubeheaderimg}`} />
             </div> 
             <div className='youtube-container'>
                  <div className='videocover'>  
                       <YoutubeEmbed embedId="Ig-7fST0_Oo" />
                  </div>
                  <div className='videocover'>  
                       <YoutubeEmbed embedId="t9w2YZRIDbU" />
                  </div>
                  <div className='videocover'>  
                       <YoutubeEmbed embedId="BAqs-Y5t6JU" />
                  </div>
             </div>
             <div className='youtube-footer'>
                 <a href='https://www.youtube.com/channel/UCczJH_-StJFc17YiZuMyRSg' target='blank'> 
                <Image src={window.location.origin + `/Images/${Data.Youtubesection.yuoutubefooterimg}`} /> 
                </a>
             </div>
        </div>
    </div>
    </section>
  )
}

export default Youtubesection