import React, { useEffect, useState } from "react";
import Data from "../../Data/data.json";
import Image from "../Image/Image";
import { Link } from "react-router-dom";
import "./News.scss";
import apis from "../apis/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CATEGORIES_POST, NEWS_LOADING } from "../../Constant";
import loader from "../../Images/loader.gif";

const News = () => {
  const [item, setItem] = useState([]);
  const dataRedux = useSelector((state) => state);
  // console.log(dataRedux?.CommonState?.LOADER?.NEWS_LOADING);
  // console.log(dataRedux);
  const dispatch = useDispatch();
  // console.log(item);
  useEffect(() => {
    getPosts();
  }, []);
  useEffect(()=>{
    setItem(dataRedux?.CommonState?.CATEGORIES_POST)
  },[dataRedux?.CommonState?.CATEGORIES_POST])
  const getPosts = () => {
    apis
      .getPosts()
      .then((res) => {
        console.log("blog response", res);
        // setItem(res);
        dispatch({
          type:CATEGORIES_POST,
          payload : res
        })
        dispatch({
          type: NEWS_LOADING,
          payload: false,
        });
      })
      .catch((err) => console.log("err setting posts", err));
  };
  return (
    <section className="news-section cmn-padding">
      <div className="container">
        <div className="sec-title">
          <h2>Featured News</h2>
        </div>
        {!dataRedux?.CommonState?.LOADER?.NEWS_LOADING && (
          <div className="post-wrap">
            <ul className="post-grid left">

              {item?.map((item, index) => {
                if (index > 4) {
                  return;
                }
                return (
                  <li key={index}>
                    <a href={item?.link} className="repeatnewsa">
                      <div className="post-img">
                        <Image src={item?.acf?.featured_image} />
                      </div>
                      <div className="post-content">
                        {/* <h5>{item.postTitle}</h5>
                      <h6>{item.publishDate}</h6> */}
                        <h5>{item?.title?.rendered}</h5>
                        <div
                        className="newslistcontent"
                          dangerouslySetInnerHTML={{
                            __html: `${
                              item?.content?.rendered?.length > 250
                                ? `${item?.content?.rendered?.slice(0, 250)}...`
                                : item?.content?.rendered
                            }`,
                          }}
                        ></div>
                        <div className="read-more-btn">
                          <a href={item?.link}>Read More</a>
                        </div>

              
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
            {/* <ul className='post-grid right'>
            {
              Data.News.filter(fitem => fitem.viewMore === true).map((item, index) => (
                <li key={index}>
                  <Link to={item?.link}>
                    <div className="post-img">
                      <Image src={item?.acf?.featured_image} />
                    </div>
                    <div className="post-content">
                      <h5>{item.postTitle}</h5>
                      <h6>{item.publishDate} - <span>{item.minRead}</span></h6>
                    </div>
                  </Link>
                </li>
              ))
            }
          </ul> */}
          </div>
        )}
        {dataRedux?.CommonState?.LOADER?.NEWS_LOADING && <section className="loaderimage"><img src={loader} /></section>}
        <div className="btnLink">
          <a href="https://rnews-wp.devserapp.com/" >Read More</a>
        </div>
      </div>
    </section>
  );
};

export default News;
