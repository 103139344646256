import React, { useEffect, useState } from "react";
import "./Breakingnews.scss";
import Data from "../../Data/data.json";
import apis from "../apis";

const Breakkingnews = () => {
  const [item, setItem] = useState();
  useEffect(() => {
    apis
      .getBreakingNews()
      .then((res) => {
        setItem(res[0]?.acf?.breaking_news);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <div className="container">
        <div className="breakingcover">
          <div className="breakingnewstext">
            <h4>Breaking News</h4>
          </div>

          <marquee width="100%" direction="left" className="breakingnewsmarq">
            <div className="breakingnewscontent">
              {item?.map((i, index) => (
                <p key={index}>{i?.news_title}</p>
              ))}
              {/* <p>{Data.Breakingsection.breakingnews1}</p>
              <p>{Data.Breakingsection.breakingnews2}</p>
              <p>{Data.Breakingsection.breakingnews3}</p>
              <p>{Data.Breakingsection.breakingnews4}</p> */}
            </div>
          </marquee>
        </div>
      </div>
    </>
  );
};

export default Breakkingnews;
