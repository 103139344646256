import React, { Component, useEffect, useState } from "react";
import Data from "../../Data/data.json";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Image from "../Image/Image";
import "./Teamslider.scss";
import apis from "../apis";

const Teamslider = () => {
  const [item, setItem] = useState();
  // console.log("team_slider", item);
  useEffect(() => {
    apis
      .getBottomTeamSlider()
      .then((res) => {
        setItem(res[0]?.acf?.bottom_team_slider);
      })
      .catch((err) => console.log(err));
  }, []);
  const postersettings = {
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="teamslider-section">
      <Slider {...postersettings} className="poster-slider-wrap center">
        {item?.map((item, index) => {
          if (!item?.bottom_team_slider_image?.url) {
            return;
          }
          return (
            <div key={index} className="teamslider-slideBox">
              <div className="teamslider-slide-img">
                <Image
                  src={item?.bottom_team_slider_image?.url}
                  alt="Slider Image"
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};
export default Teamslider;
